import React from 'react';
import { Image } from 'react-native';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { View } from '../../theme';
import { Button } from '../../theme/Button';
import { Screen } from '../../theme/Screen';
import { SaveButton } from './SaveButton';

export function ImageEditor({ data, handleReset }) {
	const size =
		isWeb || isChromeExtension
			? 300
			: themeConfig.dimensions.screenWidth / 1.3;
	return (
		<Screen>
			<View width="100%" flex center height={size}>
				<View
					alignSelf="center"
					borderRadius={themeConfig.roundness.xLarge}
					borderWidth={1}
					center
					borderColor={themeConfig.paletteScheme.text.accent}
					height={size}
					width={size}
					justifyContent="flex-start"
					alignItems="flex-start"
					paddingVertical={10}>
					<Image
						resizeMode="cover"
						source={{ uri: data?.data[0].url }}
						style={{
							borderRadius: themeConfig.roundness.xLarge,
							overflow: 'hidden',
							// width: 100,
							width: size,
							height: size,
							// height: themeConfig.dimensions.screenWidth / 1.3,
						}}
					/>
				</View>
				<View row center marginTop={10}>
					<Button
						marginRight={10}
						onPress={handleReset}
						label="New Image"
					/>
					{!isChromeExtension && (
						<SaveButton
							url={data?.data[0].url}
							style={{ width: 45, height: 45 }}
						/>
					)}
				</View>
			</View>
		</Screen>
	);
}
