import * as React from 'react';
import { useMemo } from 'react';
import { Platform, StatusBar, StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useMediaQuery } from 'react-responsive';
import { useNavigation } from '@react-navigation/native';
import { View } from './View';
import { isChromeExtension, isWeb, themeConfig } from './themeConfig';
import { Coins } from '../features/billing/Coins';
import { Icon } from './Icon';
import { Button } from './Button';

const scrollStyle = StyleSheet.create({
	outer: {
		width: '100%',
		height: '100%',
	},
	inner: {
		justifyContent: 'flex-start',
		alignItems: 'stretch',
	},
});

const fixedStyles = StyleSheet.create({
	outer: {
		flex: 1,
		height: '100%',
	},
	inner: {
		justifyContent: 'flex-start',
		alignItems: 'stretch',
		height: '100%',
		width: '100%',
	},
});

export function Screen({
	preset,
	style,
	unsafe,
	hideCoins,
	barColor,
	barStyle,
	topBar,
	children,
	paddingHorizontal = 20,
	paddingVertical,
	flex,
	contentFlexGrow,
	showPersonas,
	keyboardShouldPersistTaps,
	onSharePress,
	...props
}) {
	const insets = useSafeAreaInsets();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1224px)',
	});
	const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });

	const isFixed = useMemo(() => !preset || preset === 'fixed', [preset]);
	const presetStyles = isFixed ? fixedStyles : scrollStyle;

	const fixStyle = {
		paddingHorizontal:
			isWeb && (isBigScreen || isDesktopOrLaptop)
				? themeConfig.dimensions.screenWidth / 4
				: isFixed
				? paddingHorizontal
				: undefined,
		paddingTop:
			unsafe || topBar
				? paddingVertical
				: Platform.OS === 'ios'
				? insets.top
				: Platform.OS === 'android'
				? StatusBar.currentHeight
				: 20,
		paddingBottom: 0,
		height: isFixed ? '100%' : undefined,
		width: isFixed ? '100%' : undefined,
		...(isFixed ? style : {}),
	};
	const navigation = useNavigation();

	const statusBarStyle =
		themeConfig.colorScheme === 'dark' ? 'light-content' : 'dark-content';

	return (
		<View
			{...props}
			style={presetStyles.outer}
			backgroundColor={themeConfig.paletteScheme.background.primary}>
			<StatusBar
				barStyle={barStyle || statusBarStyle}
				backgroundColor={
					barColor || themeConfig.paletteScheme.background.primary
				}
			/>
			<View style={fixStyle}>
				<View row alignItems="center" justifyContent="space-between">
					{!hideCoins && <Coins />}
					<View row>
						{!!showPersonas && !isWeb && !isChromeExtension && (
							<Button
								onPress={() =>
									navigation.navigate('PersonaSelector')
								}
								icon={
									<Icon
										size={themeConfig.fontSize.large}
										name="drama-masks"
										type="MaterialCommunityIcons"
									/>
								}
								iconOnly
							/>
						)}
						{!!onSharePress && (
							<Button
								marginLeft={10}
								onPress={onSharePress}
								icon={
									<Icon
										size={themeConfig.fontSize.large}
										name="share"
										type="Feather"
									/>
								}
								iconOnly
							/>
						)}
					</View>
				</View>
				{/* <View */}
				{/*	justifyContent="center" */}
				{/*	alignItems="center" */}
				{/*	style={{ */}
				{/*		borderBottomStyle: 'solid', */}
				{/*		borderBottomWidth: 1, */}
				{/*		borderBottomColor: */}
				{/*			themeConfig.paletteScheme.background.secondary, */}
				{/*		paddingVertical: 5, */}
				{/*	}}> */}
				{/*	<Text */}
				{/*		weight={600} */}
				{/*		style={{ letterSpacing: 3 }} */}
				{/*		color={themeConfig.paletteScheme.text.accent} */}
				{/*		size={themeConfig.fontSize.xSmall}> */}
				{/*		{title.toUpperCase()} */}
				{/*	</Text> */}
				{/* </View> */}
				{isFixed && children}
			</View>
		</View>
	);
}
