import { useSelector } from 'react-redux';

export const usePersonas = () => {
	const { personas = [] } = useSelector(state => state.personaReducer);
	return personas;
};

export const useActivePersonas = () => {
	const { activePersona = {} } = useSelector(state => state.personaReducer);
	return activePersona;
};
