import React from 'react';
import { FlatList } from 'react-native';
import { Screen } from '../../theme/Screen';
import { View } from '../../theme';
import { useExplore } from './useExplore';
import { ExploreHorizontalList } from './ExploreHorizontalList';
import { EmptyState } from '../emptyState/EmptyState';
import { themeConfig } from '../../theme/themeConfig';
import { useAnalytics } from '../../firebase/useAnalytics';

export function ExploreScreen() {
	useAnalytics('ExploreScreen');
	const { data } = useExplore();
	const renderRow = ({ item }) => <ExploreHorizontalList item={item} />;

	return (
		<Screen>
			<View marginHorizontal={-20}>
				<FlatList
					ListEmptyComponent={() => (
						<View
							height={themeConfig.dimensions.screenHeight * 0.7}
							center>
							<EmptyState
								materialCommunityIcon="magnify"
								description="Explore contents are not available"
							/>
						</View>
					)}
					data={data.filter(item => item?.enable)}
					renderItem={renderRow}
				/>
			</View>
		</Screen>
	);
}
