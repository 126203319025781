import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { View } from './View';
import { Icon } from './Icon';
import { themeConfig } from './themeConfig';
import { Text } from './Text';

export function BackButton({ onPress, ...props }) {
	const navigation = useNavigation();

	return (
		<View
			paddingVertical={10}
			alignItems="center"
			row
			{...props}
			onPress={() => (onPress ? onPress() : navigation.goBack())}>
			<Icon
				size={25}
				name="chevron-left"
				type="MaterialIcons"
				color={themeConfig.paletteScheme.text.accent}
			/>
			<Text
				color={themeConfig.paletteScheme.text.accent}
				paddingTop={5}
				size={themeConfig.fontSize.small}>
				Back
			</Text>
		</View>
	);
}
