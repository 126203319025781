import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { themeConfig } from '../../theme/themeConfig';
import { Text, View } from '../../theme';

export function EmptyState({
	onPress,
	actionLabel,
	component,
	description,
	materialCommunityIcon = 'history',
}) {
	return (
		<>
			{!component && (
				<MaterialCommunityIcons
					color={themeConfig.paletteScheme.background.accentMelow}
					name={materialCommunityIcon}
					size={80}
				/>
			)}
			{!!component && component}
			<Text
				size={themeConfig.fontSize.semiSmall}
				color={themeConfig.paletteScheme.text.disabled}>
				{description}
			</Text>
			{onPress && (
				<View onPress={onPress}>
					<Text
						size={themeConfig.fontSize.medium}
						color={themeConfig.paletteScheme.text.accent}>
						{actionLabel}
					</Text>
				</View>
			)}
		</>
	);
}
