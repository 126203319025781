import {
	analytics as Wanalytics,
	auth as WAuth,
	crashlytics as Wcrashlytics,
	database as WDB,
	firestore as WFirestore,
} from './fb.web';

export const crashlytics = Wcrashlytics();
export const analytics = Wanalytics();
export const auth = WAuth();
export const authInstance = WAuth;
export const database = WDB();
export const firestore = WFirestore();
export const fsInstance = WFirestore;
