import React from 'react';
import { Pressable, View as RNView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { themeConfig } from './themeConfig';

export function View({
	children,
	animated,
	flex,
	center,
	backgroundColor,
	row,
	overflow,
	justifyContent,
	alignItems,
	alignSelf,
	borderRadius,
	borderWidth,
	borderBottomWidth,
	borderColor,
	style,
	width,
	height,
	marginVertical,
	marginHorizontal,
	paddingVertical,
	paddingHorizontal,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	paddingBottom,
	paddingTop,
	paddingLeft,
	paddingRight,
	position,
	onPress,
	elevation,
	disabled,
	colors = [],
	startX = 0.5,
	startY = 1,
	endX = 0.5,
	endY = 0,
	...props
}) {
	const additionalStyle = {
		flex: flex ? 1 : undefined,
		justifyContent: (center && 'center') || justifyContent || undefined,
		alignItems: (center && 'center') || alignItems || undefined,
		flexDirection: row ? 'row' : undefined,
		alignSelf,
		backgroundColor,
		borderRadius,
		borderWidth,
		position,
		borderBottomWidth,
		borderColor,
		width,
		height,
		marginVertical,
		marginHorizontal,
		paddingVertical,
		paddingHorizontal,
		marginTop,
		marginBottom,
		marginLeft,
		marginRight,
		paddingBottom,
		paddingTop,
		paddingLeft,
		paddingRight,
		row,
		disabled,
		elevation,
		onPress,
		overflow,
		startX,
		startY,
		endX,
		endY,
		...style,
	};
	if (colors.length > 0) {
		return (
			<LinearGradient
				style={[{ ...additionalStyle, ...style }]}
				{...props}
				colors={colors}
				start={{ x: startX, y: startY }}
				end={{ x: endX, y: endY }}>
				{children}
			</LinearGradient>
		);
	}
	if (onPress) {
		return (
			<Pressable
				android_ripple={{
					color: themeConfig.paletteScheme.background.accentMelow,
				}}
				disabled={disabled}
				onPress={onPress}
				style={[
					{ ...additionalStyle, ...style },
					{ opacity: disabled && 0.5 },
				]}
				{...props}>
				{children}
			</Pressable>
		);
	}

	return (
		<RNView style={{ ...additionalStyle, ...style }} {...props}>
			{children}
		</RNView>
	);
}
