import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { Icon } from '../../theme/Icon';

export function ProfileMenuItem({
	label,
	onPress,
	iconContainerColor = themeConfig.paletteScheme.background.accentMelow,
	icon,
	labelColor = themeConfig.paletteScheme.text.primary,
	iconType = 'Material',
	iconSize = 16,
	iconColor = themeConfig.paletteScheme.text.accent,
	sublabel,
	loading,
	...props
}) {
	return (
		<View
			disabled={loading}
			row
			alignItems="center"
			borderRadius={themeConfig.roundness.medium}
			paddingHorizontal={15}
			paddingVertical={10}
			backgroundColor={themeConfig.paletteScheme.background.secondary}
			onPress={onPress}
			marginVertical={5}
			{...props}>
			{!!icon && (
				<View
					width={32}
					height={32}
					center
					borderRadius={5}
					marginRight={10}
					backgroundColor={iconContainerColor}>
					{iconType !== 'custom' && (
						<Icon
							size={iconSize}
							color={iconColor}
							name={icon}
							type={iconType}
						/>
					)}
					{iconType === 'custom' && icon}
				</View>
			)}
			<Text
				color={labelColor}
				paddingTop={4}
				size={themeConfig.fontSize.semiSmall}>
				{label}
			</Text>
			{!!sublabel && (
				<View
					marginLeft={10}
					paddingHorizontal={5}
					paddingTop={4}
					paddingBottom={1}
					style={{
						borderRadius: themeConfig.roundness.small,
						backgroundColor:
							themeConfig.paletteScheme.background.accentMelow,
					}}>
					<Text size={themeConfig.fontSize.xxSmall}>{sublabel}</Text>
				</View>
			)}
			{!!loading && (
				<View marginLeft={10}>
					<ActivityIndicator
						color={themeConfig.paletteScheme.background.accent}
					/>
				</View>
			)}
		</View>
	);
}
