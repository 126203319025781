import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Screen } from '../../theme/Screen';
import { usePersonas } from './usePersonas';
import { PersonaAddStepOne } from './PersonaAddStepOne';
import { reduxPersonaSelect, reduxPersonaSubmit } from '../redux/actions';
import { PersonaAddStepTwo } from './PersonaAddStepSeconds';
import { View } from '../../theme';

export function PersonaAddScreen() {
	const personas = usePersonas();
	const pending = personas.find(item => !item?.prompt) || {};
	const [state, setState] = useState(pending);
	const [step, setStep] = useState(1);
	const dispatch = useDispatch();

	const handleSubmit = data => {
		setState(data);
		dispatch(reduxPersonaSubmit(data));
		setStep(prevState => prevState + 1);
		if (step > 1) {
			dispatch(reduxPersonaSelect(data));
		}
	};
	const navigation = useNavigation();

	useEffect(() => {
		step > 2 && navigation.goBack();
	}, [navigation, step]);

	return (
		<Screen>
			<View paddingBottom={30} flex>
				{step === 1 && (
					<PersonaAddStepOne onNext={handleSubmit} pending={state} />
				)}
				{step === 2 && (
					<PersonaAddStepTwo onNext={handleSubmit} pending={state} />
				)}
			</View>
		</Screen>
	);
}
