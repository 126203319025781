import React from 'react';
import { Image, Linking } from 'react-native';
import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons';
import { Screen } from '../../theme/Screen';
import { Text, View } from '../../theme';
import { Button } from '../../theme/Button';
import { isAndroid, themeConfig } from '../../theme/themeConfig';
import { onAppleButtonPress, onGoogleButtonPress } from '../../firebase/utils';
import { useAnalytics } from '../../firebase/useAnalytics';
import { analytics } from '../../firebase';

export function SignupScreen({ onClosePress }) {
	useAnalytics('SignupScreen');
	const onPrivacyClick = async () => {
		await analytics.logEvent('PrivacyClicked');
		Linking.openURL('https://telepati.info/privacy-policy');
	};
	return (
		<Screen>
			{!!onClosePress && (
				<View alignItems="flex-end">
					<Button
						onPress={() => onClosePress()}
						icon={
							<MaterialCommunityIcons
								size={themeConfig.fontSize.medium}
								name="close"
							/>
						}
						iconOnly
					/>
				</View>
			)}
			<View flex center>
				<Image
					source={require('../../assets/logo.png')}
					style={{ width: 100, height: 100 }}
				/>
				<Text weight={600} size={themeConfig.fontSize.medium}>
					Telepati account
				</Text>
				<Text
					paddingTop={10}
					paddingHorizontal={20}
					size={themeConfig.fontSize.small}>
					If this is your first time, we will create your account
					automatically
				</Text>
				{/* <Text paddingVertical={10} size={themeConfig.fontSize.medium}> */}
				{/*	Create a new account */}
				{/* </Text> */}
				{/* <View width="100%" marginBottom={20}> */}
				{/*	<TextInput marginBottom={15} shine placeholder="Email" /> */}
				{/*	<TextInput */}
				{/*		marginBottom={15} */}
				{/*		shine */}
				{/*		placeholder="Password" */}
				{/*		secureTextEntry */}
				{/*	/> */}
				{/*	<Button */}
				{/*		size={themeConfig.fontSize.small} */}
				{/*		style={{ paddingTop: isAndroid ? 10 : 15 }} */}
				{/*		label="Register" */}
				{/*	/> */}
				{/* </View> */}
				{/* <View row center> */}
				{/*	<Text>Already have an account? </Text> */}
				{/*	<Text */}
				{/*		size={themeConfig.fontSize.small} */}
				{/*		weight={600} */}
				{/*		color={themeConfig.paletteScheme.text.accent}> */}
				{/*		Login */}
				{/*	</Text> */}
				{/* </View> */}
				<View marginTop={20}>
					<View
						borderRadius={themeConfig.roundness.medium}
						paddingHorizontal={20}
						paddingVertical={10}
						backgroundColor={
							themeConfig.paletteScheme.background.accentMelow
						}
						row
						onPress={onGoogleButtonPress}
						alignItems="center">
						<AntDesign
							size={themeConfig.fontSize.xxLarge * 1.5}
							name="google"
							color={themeConfig.paletteScheme.text.primary}
						/>
						<Text
							paddingTop={5}
							paddingHorizontal={10}
							size={themeConfig.fontSize.medium}>
							Connect using Google
						</Text>
					</View>
					{!isAndroid && (
						<View
							borderRadius={themeConfig.roundness.medium}
							paddingHorizontal={20}
							paddingVertical={15}
							backgroundColor={
								themeConfig.paletteScheme.background.accentMelow
							}
							row
							onPress={onAppleButtonPress}
							marginTop={20}>
							<AntDesign
								style={{ marginTop: -5 }}
								size={themeConfig.fontSize.xxLarge * 1.5}
								name="apple1"
								color={themeConfig.paletteScheme.text.primary}
							/>
							<Text
								paddingTop={5}
								paddingHorizontal={10}
								size={themeConfig.fontSize.medium}>
								Connect using Apple ID
							</Text>
						</View>
					)}
				</View>
			</View>
			{/* <View */}
			{/*	height={1} */}
			{/*	width="100%" */}
			{/*	backgroundColor={ */}
			{/*		themeConfig.paletteScheme.background.accentMelow */}
			{/*	} */}
			{/* /> */}
			{/* <Text textAlign="center" paddingVertical={20} weight={600}> */}
			{/*	Or signup with other accounts */}
			{/* </Text> */}
			{/* <View */}
			{/*	justifyContent="space-around" */}
			{/*	paddingBottom={50} */}
			{/*	paddingHorizontal={themeConfig.dimensions.screenWidth / 4} */}
			{/*	row> */}
			{/*	<View onPress={onGoogleButtonPress}> */}
			{/*		<AntDesign */}
			{/*			size={themeConfig.fontSize.xxLarge * 1.5} */}
			{/*			name="google" */}
			{/*			color={themeConfig.paletteScheme.text.primary} */}
			{/*		/> */}
			{/*	</View> */}
			{/*	{!isAndroid && ( */}
			{/*		<View onPress={onAppleButtonPress}> */}
			{/*			<AntDesign */}
			{/*				style={{ marginTop: -5 }} */}
			{/*				size={themeConfig.fontSize.xxLarge * 1.5} */}
			{/*				name="apple1" */}
			{/*				color={themeConfig.paletteScheme.text.primary} */}
			{/*			/> */}
			{/*		</View> */}
			{/*	)} */}
			{/* </View> */}
			<Text textAlign="center">
				To become a pro user, you need to have an account in Telepati.
				By connecting your social account, you agreed to share your
				email and name with us
			</Text>
			<View onPress={onPrivacyClick}>
				<Text
					paddingVertical={10}
					color={themeConfig.paletteScheme.text.accent}
					textAlign="center">
					Review our Privacy Policy
				</Text>
			</View>
		</Screen>
	);
}
