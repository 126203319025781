import { personaJohan, personaTelepati } from '../../personas/config';
import { PERSONA_ADD, PERSONA_SELECT, PERSONA_SUBMIT } from '../actions';

const initState = {
	activePersona: personaTelepati,
	personas: [personaTelepati, personaJohan],
};
export const personaReducer = (state = initState, action) => {
	switch (action.type) {
		case PERSONA_SELECT:
			return { ...state, activePersona: action.data };
		case PERSONA_ADD:
			return {
				...state,
				personas: [...state.personas, { ...action.data }],
			};
		case PERSONA_SUBMIT: {
			return {
				...state,
				personas: [
					...state.personas.filter(item => !!item?.prompt),
					{ ...action.data },
				],
			};
		}
		default:
			return state;
	}
};
