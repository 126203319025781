import React, { useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import moment from 'moment/moment';
import { Text, View } from '../../theme';
import { Screen } from '../../theme/Screen';
import { Eclips } from './Eclips';
import { isAndroid, isIos, themeConfig } from '../../theme/themeConfig';
import { Button } from '../../theme/Button';
import FadeView from '../../theme/FadeView';
import { useAnalytics } from '../../firebase/useAnalytics';
import { firestore } from '../../firebase';
import { usePackages } from './usePackages';
import { subscriptionInfoProviders } from './config';
import { SignupScreen } from '../signup/SignupScreen';

export function PaywallScreen() {
	useAnalytics('PaywallScreen');
	const { packages } = usePackages();
	const [isRegisterVisible, setIsRegisterVisible] = useState(false);
	const [paywall, setPaywall] = useState();
	const [billingDate, setBillingDate] = useState('');
	useEffect(() => {
		const getSettings = async () => {
			const settings = await firestore
				.collection('settings')
				.doc('general')
				.get();
			const sData = settings.data();
			setBillingDate(sData?.startBilling || '');
		};
		const getPrice = async () => {
			const settings = await firestore
				.collection('settings')
				.doc('paywall')
				.get();
			const sData = settings.data();
			!!sData && setPaywall(sData);
		};
		getPrice();
		getSettings();
	}, []);
	const termsPress = () => {
		Linking.openURL(
			isIos
				? 'https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'
				: 'https://telepati.info/terms',
		);
	};

	const privacyPress = () => {
		Linking.openURL('https://telepati.info/privacy-policy');
	};

	if (isRegisterVisible)
		return (
			<SignupScreen onClosePress={() => setIsRegisterVisible(false)} />
		);

	return (
		<Screen>
			<FadeView delay={500} duration={1000} visible>
				<Eclips size={themeConfig.dimensions.screenWidth * 1.5} />
			</FadeView>
			<View flex center>
				<View row>
					<View style={{ opacity: 0 }}>
						<Text
							style={{
								backgroundColor:
									themeConfig.paletteScheme.background
										.highlight,
								borderRadius: themeConfig.roundness.small,
								overflow: 'hidden',
							}}
							paddingTop={Platform.OS === 'android' ? 3 : 5}
							paddingHorizontal={5}>
							Account
						</Text>
					</View>
					<Text
						textAlign="center"
						weight={600}
						size={themeConfig.fontSize.xLarge}>
						Telepati
					</Text>
					<View marginTop={-10}>
						<Text
							style={{
								backgroundColor:
									themeConfig.paletteScheme.background
										.highlight,
								borderRadius: themeConfig.roundness.small,
								overflow: 'hidden',
							}}
							paddingTop={Platform.OS === 'android' ? 3 : 5}
							paddingHorizontal={5}>
							Account
						</Text>
					</View>
				</View>
				{paywall?.subscriptionInfoProvider !==
					subscriptionInfoProviders.revenuecat &&
					(billingDate || paywall?.totallyFree) && (
						<View
							marginBottom={10}
							borderRadius={themeConfig.roundness.small}
							backgroundColor={
								themeConfig.palette.dark.background.primary
							}
							paddingVertical={5}
							paddingHorizontal={10}>
							{paywall?.subscriptionInfoProvider !==
								subscriptionInfoProviders.revenuecat && (
								<Text
									weight={600}
									paddingTop={4}
									color={
										themeConfig.paletteScheme.background
											.highlight
									}>
									{!paywall?.totallyFree && billingDate
										? `Free until ${moment(
												billingDate,
										  ).format('Do of MMM')}`
										: "It's free now"}
								</Text>
							)}
							{paywall?.subscriptionInfoProvider ===
								subscriptionInfoProviders.revenuecat && (
								<Text
									weight={600}
									paddingTop={4}
									color={
										themeConfig.paletteScheme.background
											.highlight
									}>
									{packages?.length > 0
										? packages[0]?.product?.priceString
										: "It's free now"}
								</Text>
							)}
						</View>
					)}

				<View>
					{!!paywall &&
						isIos &&
						paywall?.benefitsApple?.map((item, index) => (
							<Text
								key={index}
								paddingTop={10}
								size={themeConfig.fontSize.semiSmall}>
								<MaterialCommunityIcons
									color={
										themeConfig.paletteScheme.background
											.highlight
									}
									size={themeConfig.fontSize.medium}
									name="check"
								/>{' '}
								{item}
							</Text>
						))}
					{!!paywall &&
						!isIos &&
						isAndroid &&
						paywall?.benefitsAndroid?.map((item, index) => (
							<Text
								key={index}
								paddingTop={10}
								size={themeConfig.fontSize.semiSmall}>
								<MaterialCommunityIcons
									color={
										themeConfig.paletteScheme.background
											.highlight
									}
									size={themeConfig.fontSize.medium}
									name="check"
								/>{' '}
								{item}
							</Text>
						))}
				</View>
			</View>
			<View marginBottom={25} center>
				<Button
					backgroundColor={
						themeConfig.paletteScheme.background.accent
					}
					color={themeConfig.paletteScheme.background.primary}
					weight={600}
					onPress={() => setIsRegisterVisible(true)}
					style={{ paddingTop: 15, paddingHorizontal: 40 }}
					label="Login / Register"
				/>
			</View>
			{paywall?.showRestore && (
				<View onPress={() => setIsRegisterVisible(true)}>
					<Text weight={600} paddingVertical={5} textAlign="center">
						Restore Purchase
					</Text>
				</View>
			)}
			{paywall?.showTerms && (
				<View paddingVertical={5} onPress={termsPress}>
					<Text textAlign="center">Terms & Conditions</Text>
				</View>
			)}
			{paywall?.showPrivacy && (
				<View paddingVertical={5} onPress={privacyPress}>
					<Text textAlign="center">Privacy Policy</Text>
				</View>
			)}
		</Screen>
	);
}
