import { useEffect, useState } from 'react';
import { firestore } from '../../firebase';

export const useExplore = () => {
	const [data, setData] = useState([]);
	useEffect(() => {
		const subscriber = firestore
			.collection('explore')
			.onSnapshot(querySnapshot => {
				// if (!documentSnapshot.exist) return;
				const explore = [];
				querySnapshot.forEach(doc => {
					explore.push(doc.data());
				});
				setData(explore);
			});

		// Stop listening for updates when no longer required
		return () => subscriber();
	}, []);

	return { data };
};
