import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useProfile } from '../profile/useProfile';
import { addChat, removeChat, setChatMessages } from '../redux/actions';
import { firestore, fsInstance } from '../../firebase';

export const useChatLogic = () => {
	const [onlineChats, setOnlineChats] = useState([]);
	const dispatch = useDispatch();
	const profile = useProfile();
	const { chats: offlienChats = [] } = useSelector(
		state => state.chatReducer,
	);

	useEffect(() => {
		if (!profile?.uid) return;
		const subscriber = firestore
			.collection('history')
			.doc(profile.uid)
			.onSnapshot(documentSnapshot => {
				// if (!documentSnapshot.exist) return;
				const chats = documentSnapshot?.data()?.chats || [];
				setOnlineChats(chats);
			});

		// Stop listening for updates when no longer required
		return () => subscriber();
	}, [dispatch, profile.uid]);

	const deleteChat = async index => {
		if (profile?.uid) {
			await firestore
				.collection('history')
				.doc(profile.uid)
				.update({
					chats: fsInstance.FieldValue.arrayRemove(
						onlineChats[index],
					),
				});
		} else {
			dispatch(removeChat(null, index));
		}
	};

	const addMessage = async (messages, activeIndex) => {
		if (profile?.uid) {
			const docRef = firestore.collection('history').doc(profile.uid);
			const docData = await docRef.get();
			if (!docData.exists) return;
			const chats = docData.data();
			const newChats = [...chats.chats];

			newChats[activeIndex].messages = messages;
			await docRef.update({
				chats: newChats,
			});
		} else {
			dispatch(setChatMessages(messages, null, activeIndex));
		}
	};

	const createNewChat = async data => {
		if (profile?.uid) {
			await firestore
				.collection('history')
				.doc(profile.uid)
				.update({
					chats: fsInstance.FieldValue.arrayUnion(data),
				});
		} else {
			dispatch(addChat(data));
		}
	};

	return {
		addMessage,
		createNewChat,
		deleteChat,
		chats: profile?.uid ? onlineChats : offlienChats,
	};
};
