import React from 'react';
import { ImageBackground, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { isWeb, themeConfig } from '../../theme/themeConfig';
import { Text, View } from '../../theme';
import { CoinIcon } from '../billing/CoinIcon';
import { useBilling } from '../billing/useBilling';
import { analytics } from '../../firebase';

export function ExploreItem({ item }) {
	const navigation = useNavigation();
	const { spendForChat, amount } = useBilling();

	const handleClickNew = async initialPrompt => {
		await analytics.logEvent('ExploreClicked');
		const intPrice = item?.price ? parseInt(item?.price, 10) : 0;
		if (amount < (intPrice || 1)) {
			Toast.show({
				type: 'error',
				text1: 'Oh No!',
				text2: 'You do not have enough coins, Please add some coins',
			});
			await analytics.logEvent('CoinsFinished');
			return;
		}
		intPrice > 0 && (await spendForChat(intPrice));
		if (!initialPrompt) return;
		if (isWeb) {
			navigation.navigate('Home', { newChat: true, initialPrompt });
		} else {
			navigation.navigate('Home', { newChat: true, initialPrompt });
			// navigation.navigate('AdScreen', { newChat: true });
		}
	};
	return (
		<TouchableOpacity onPress={() => handleClickNew(item?.prompt)}>
			<ImageBackground
				source={{ uri: item?.image }}
				style={{
					borderRadius: themeConfig.roundness.medium,
					borderWidth: 0,
					borderColor: themeConfig.paletteScheme.background.accent,
					overflow: 'hidden',
				}}>
				<View
					colors={[
						themeConfig.paletteScheme.background.accentMelow,
						item?.image
							? themeConfig.paletteScheme.background.primary
							: themeConfig.paletteScheme.background.accentMelow,
					]}
					paddingTop={10}
					paddingHorizontal={10}
					borderRadius={themeConfig.roundness.medium}
					backgroundColor={
						themeConfig.paletteScheme.background.accentMelow
					}
					width={themeConfig.dimensions.screenWidth / 3}
					justifyContent="space-between"
					height={themeConfig.dimensions.screenWidth / 3}>
					<Text>{item?.title || item?.label}</Text>
					{!!item?.price && (
						<View
							backgroundColor={
								themeConfig.paletteScheme.background.primary
							}
							row
							alignSelf="flex-end"
							style={{
								borderTopEndRadius:
									themeConfig.roundness.medium,
								borderTopStartRadius:
									themeConfig.roundness.medium,
							}}
							justifyContent="flex-end"
							paddingHorizontal={10}
							paddingTop={5}>
							<CoinIcon
								color={themeConfig.paletteScheme.text.accent}
							/>
							<Text
								paddingTop={2}
								color={themeConfig.paletteScheme.text.primary}
								weight={themeConfig.fontWeight.bold}>
								{'  '}-{item?.price}
							</Text>
						</View>
					)}
					{!item?.price && (
						<View
							backgroundColor={
								themeConfig.paletteScheme.background.primary
							}
							row
							alignSelf="flex-end"
							style={{
								borderTopEndRadius:
									themeConfig.roundness.medium,
								borderTopStartRadius:
									themeConfig.roundness.medium,
							}}
							paddingHorizontal={10}
							paddingTop={5}
							justifyContent="flex-end">
							<Text
								color={themeConfig.paletteScheme.text.accent}
								weight={themeConfig.fontWeight.bold}>
								Free
							</Text>
						</View>
					)}
				</View>
			</ImageBackground>
		</TouchableOpacity>
	);
}
