import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlatList, KeyboardAvoidingView, Platform } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { DotIndicator } from 'react-native-indicators';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import moment from 'moment';
import Toast from 'react-native-toast-message';
import { ChatItem } from '../Chat/ChatItem';
import { TextInput } from '../../theme/TextInput';
import { Screen } from '../../theme/Screen';
import { Button } from '../../theme/Button';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { View } from '../../theme';
import { analytics, auth, crashlytics } from '../../firebase';
import { useAnalytics } from '../../firebase/useAnalytics';
import { useChatLogic } from '../Chat/useChatLogic';
import { useBilling } from '../billing/useBilling';
import { useActivePersonas } from '../personas/usePersonas';
import { PersonaEmptyState } from '../personas/PersonaEmptyState';
import { EmptyChat } from '../emptyState/EmptyChat';

export default function Home() {
	useAnalytics('HomeScreen');
	const activePersona = useActivePersonas();
	const { params } = useRoute();
	const { spendForChat, amount } = useBilling();
	const [activeChat, setActiveChat] = useState(undefined);
	const [message, setMessage] = useState('');
	const { chats = [] } = useChatLogic();
	const [sending, setSending] = useState(false);
	const { createNewChat, addMessage } = useChatLogic();
	const renderItem = ({ item }) => <ChatItem item={item} />;
	const renderSeparator = () => <View style={{ width: 10, height: 7 }} />;
	const creating = useRef(false);
	const [token, setToken] = useState('');
	const user = auth.currentUser;
	useEffect(() => {
		user?.getIdToken().then(tk => {
			setToken(tk);
		});
	}, [user]);

	useEffect(() => {
		if (params?.newChat && !params?.initialPrompt) {
			setActiveChat(undefined);
			setMessage('');
		} else if (params?.newChat && !!params?.initialPrompt) {
			setActiveChat(undefined);
			setMessage(params?.initialPrompt);
		} else if (
			params?.index === 0 ||
			(params?.index && params?.initialPrompt)
		) {
			setActiveChat(params?.initialPrompt);
		} else if (params?.index) {
			setActiveChat(params?.index);
		}
	}, [params]);

	useEffect(() => {
		if (typeof activeChat !== 'number') return;
		if (!chats.length) return;
		if (chats.length - 1 < activeChat) return;
		if (params?.index === 0 || params?.index) return;
		const systemMessage = chats[activeChat]?.messages.find(
			chat => chat?.role === 'system',
		);
		if (systemMessage?.content !== activePersona?.prompt) {
			setActiveChat(undefined);
			setMessage('');
		}
	}, [activeChat, chats, activePersona, params?.index]);

	useEffect(() => {
		if (creating.current === true) return;
		setActiveChat(undefined);
		setMessage('');
	}, [chats.length]);
	const reversedMessages = useMemo(
		() =>
			(typeof activeChat === 'number' &&
			!!chats.length &&
			chats[activeChat]
				? [...chats[activeChat].messages]
				: []
			).reverse(),
		[activeChat, chats],
	);
	const handleSend = async (initialPrompt = '') => {
		if (amount < 1) {
			Toast.show({
				type: 'error',
				text1: 'Oh No!',
				text2: 'You do not have enough coins, Please add some coins',
			});
			await analytics.logEvent('CoinsFinished');
		}
		if ((!initialPrompt && !message) || amount < 1) return;
		const config = {
			role: 'system',
			content: activePersona.prompt,
		};
		const msg = { role: 'user', content: initialPrompt || message };
		setMessage('');
		const activeIndex =
			typeof activeChat === 'number' ? activeChat : chats.length;
		const newMessages =
			chats.length > 0 && typeof activeChat === 'number'
				? chats[activeIndex]?.messages
				: [config];
		newMessages.push(msg);

		if (typeof activeChat !== 'number') {
			creating.current = true;
			setActiveChat(chats.length);
			await spendForChat();
			await createNewChat({
				insertDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
				messages: newMessages,
			});
		} else {
			await addMessage(newMessages, activeIndex);
		}
		setSending(true);
		fetch(
			'https://us-central1-telepati-f930a.cloudfunctions.net/authorizedHello',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
				},
				body: JSON.stringify({ messages: newMessages }),
			},
		)
			.then(response => response.json())
			.then(data => {
				analytics.logEvent('reply', { ...data });
				creating.current = false;
				addMessage(
					[...newMessages, data?.choices[0]?.message],
					activeIndex,
				);
				setSending(false);
			})
			.catch(error => {
				crashlytics.recordError(error, 'Chat fetch error');
			});
	};
	return (
		<Screen showPersonas>
			{!!reversedMessages?.length && (
				<FlatList
					showsVerticalScrollIndicator={false}
					ItemSeparatorComponent={renderSeparator}
					inverted
					data={reversedMessages}
					renderItem={renderItem}
				/>
			)}
			{!reversedMessages.length &&
				!params?.initialPrompt &&
				!isWeb &&
				!isChromeExtension && <PersonaEmptyState />}
			{!reversedMessages.length &&
				(!!params?.initialPrompt || isWeb || isChromeExtension) && (
					<View flex center>
						<EmptyChat />
					</View>
				)}
			{sending && (
				<View
					justifyContent="flex-start"
					alignItems="flex-start"
					paddingVertical={10}>
					<DotIndicator
						count={3}
						size={5}
						color={themeConfig.paletteScheme.text.accent}
					/>
				</View>
			)}
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'padding' : undefined}
				style={{
					flexDirection: 'row',
					marginTop: 10,
					alignItems: 'center',
				}}>
				<TextInput
					flex
					multiline
					placeholder="Are you a robot?"
					marginRight={10}
					onSubmitEditing={() => handleSend()}
					value={message}
					onChangeText={setMessage}
				/>
				<Button
					iconOnly
					icon={
						<MaterialCommunityIcons
							size={themeConfig.fontSize.semiLarge}
							name="send"
						/>
					}
					onPress={() => handleSend()}
					title="send"
				/>
			</KeyboardAvoidingView>
		</Screen>
	);
}
