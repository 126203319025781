import { useEffect } from 'react';
import { analytics, crashlytics } from './index';

export const useAnalytics = (screen, enabled = true) => {
	useEffect(() => {
		const log = async () => {
			if (enabled) {
				crashlytics.log(screen);
				await analytics.logScreenView({ screen_name: screen });
			}
		};
		log();
	}, [enabled, screen]);
};
