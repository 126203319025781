import { combineReducers } from 'redux';
import { profileReducer } from './profileReducer';
import { chatReducer } from './chatReducer';
import { billingReducer } from './billingReducer';
import { personaReducer } from './personaReducer';

export const reducer = combineReducers({
	profileReducer,
	billingReducer,
	chatReducer,
	personaReducer,
});
