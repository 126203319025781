import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { DotIndicator } from 'react-native-indicators';
import { Text, View } from '../../theme';
import { isChromeExtension, themeConfig } from '../../theme/themeConfig';
import { analytics, auth, crashlytics, firestore } from '../../firebase/index';
import { useProfile } from '../profile/useProfile';

export function SignUpChrome() {
	const [settings, setSettings] = useState();
	const [isRegistered, setIsRegistered] = useState(false);
	const [registerResponse, setRegisterResponse] = useState();
	const [isSignedUp, setIsSignedUp] = useState(false);
	const profile = useProfile();
	useEffect(() => {
		const getSettings = async () => {
			const settingsOnFs = await firestore
				.collection('settings')
				.doc('general')
				.get();
			const sData = settingsOnFs.data();
			setSettings(sData);
		};

		getSettings().then(() => console.info('Settings fetched'));
	}, []);

	useEffect(() => {
		if (isRegistered) return;
		const register = async () => {
			const registerRecord = await firestore
				.collection('chromeExtensions')
				.doc(auth.currentUser.uid);

			await registerRecord
				.set({ insertDate: new Date().getTime() })
				.then(() => {
					console.info('Registered');
					setIsRegistered(true);
				})
				.catch(err => {
					console.error(err);
				});
		};
		!!auth?.currentUser?.uid && register();
	}, [settings, isRegistered]);

	// useEffect(() => {
	// 	const signout = async () => {
	// 		await auth.signOut();
	// 	};
	// 	signout();
	// }, []);

	useEffect(() => {
		if (!settings) return;
		if (isSignedUp || profile?.email) return;
		const signIn = async () => {
			await auth.signInAnonymously();
			setIsSignedUp(true);
		};
		signIn();
	}, [settings, isSignedUp, profile?.email]);

	useEffect(() => {
		if (!isRegistered) return;
		const subscriber = firestore
			.collection('chromeExtensions')
			.doc(auth?.currentUser?.uid)
			.onSnapshot(documentSnapshot => {
				// if (!documentSnapshot.exist) return;
				const record = documentSnapshot?.data() || [];
				setRegisterResponse(record);
			});

		// Stop listening for updates when no longer required
		return () => subscriber();
	}, [isRegistered]);

	useEffect(() => {
		if (!registerResponse?.party || !registerResponse?.token) return;
		auth.signOut().then(() => {
			auth.signInWithEmailAndPassword(
				registerResponse.party,
				registerResponse.token,
			)
				.then(() => {
					console.info('Signed in');
					analytics
						.logEvent('chrome_extension_sign_in')
						.then(() => console.info('Logged event'));
				})
				.catch(err => {
					console.error(err);
					crashlytics.recordError(
						err,
						'Chrome Extension Sign In Error',
					);
				});
		});
	}, [registerResponse]);

	// useEffect(() => {
	// 	const UUID = Crypto.randomUUID();
	// 	setUuid(UUID);
	// }, []);

	if (!isChromeExtension) return null;
	if (!settings)
		return (
			<View center height={250}>
				<DotIndicator
					color={themeConfig.paletteScheme.text.accent}
					size={15}
				/>
			</View>
		);

	return (
		<View center height={250}>
			<Text>
				Open <Text weight={700}>Telepati</Text> app. Go to{' '}
				<Text weight={700}>profile</Text>, and scan the QR code in the
				<Text weight={700}> Link to web </Text>screen
			</Text>
			<View marginTop={40} width="100%" center>
				{isRegistered && (
					<QRCode
						value={auth.currentUser.uid}
						// Setting the value of QRCode
						size={150}
						// Size of QRCode
						bgColor={themeConfig.paletteScheme.background.primary}
						// Backgroun Color of QRCode
						fgColor={themeConfig.paletteScheme.text.accent}
						// Front Color of QRCode
					/>
				)}
			</View>
		</View>
	);
}
