import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { Screen } from '../../theme/Screen';

export function AdScreen() {
	return (
		<Screen>
			<View center flex>
				<Text color={themeConfig.paletteScheme.border.disabled}>
					Thanks for supporting us.
				</Text>
				<Text color={themeConfig.paletteScheme.border.disabled}>
					Please wait to show an advertisement
				</Text>
				<Text weight={600} marginBottom={20}>
					If you see this screen in your web browser or chrome
					extension, please report a bug
				</Text>
				<ActivityIndicator
					color={themeConfig.paletteScheme.text.accent}
				/>
			</View>
		</Screen>
	);
}
