export const firebaseConfig = {
	apiKey: 'AIzaSyAxc4r71-I0acb7PWu4IEgpgPfb6LQNmmY',
	authDomain: 'telepati-f930a.firebaseapp.com',
	databaseURL:
		'https://telepati-f930a-default-rtdb.europe-west1.firebasedatabase.app',
	projectId: 'telepati-f930a',
	storageBucket: 'telepati-f930a.appspot.com',
	messagingSenderId: '179725604292',
	appId: '1:179725604292:web:c7335e04e4487d36418091',
	measurementId: 'G-BZLMLYR38J',
};

export const googleWebClientId =
	'179725604292-ngm01vffvb03ltc6sdjsgaf9k8e295ro.apps.googleusercontent.com';

export const googleIosClientId =
	'179725604292-daktpqrfnoggrp0ak9s7q1n7cpcrgso5.apps.googleusercontent.com';
