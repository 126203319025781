import { useCallback, useEffect } from 'react';
import { Appearance } from 'react-native';
import SystemNavigationBar from 'react-native-system-navigation-bar';
import { themeConfig } from '../../theme/themeConfig';

export const useSyncNavigationBarColor = () => {
	const changeNavigationBarColor = useCallback(() => {
		const light = Appearance.getColorScheme();
		SystemNavigationBar.setNavigationColor(
			themeConfig.paletteScheme.background.primary,
			light === 'light' ? 'dark' : 'light',
		);
	}, []);

	useEffect(() => changeNavigationBarColor(), [changeNavigationBarColor]);
};
