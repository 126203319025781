import React from 'react';
import { Image, Linking } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Screen } from '../../theme/Screen';
import { Text, View } from '../../theme';
import {
	isAndroid,
	isChromeExtension,
	themeConfig,
} from '../../theme/themeConfig';
import { onAppleButtonPress, onGoogleButtonPress } from '../../firebase/utils';
import { useAnalytics } from '../../firebase/useAnalytics';
import { analytics, auth } from '../../firebase';
import { SignUpChrome } from '../ChromeExtension/SignUpChrome';

export function SignInScreen() {
	useAnalytics('SignupScreen');
	const onPrivacyClick = async () => {
		await analytics.logEvent('PrivacyClicked');
		Linking.openURL('https://telepati.info/privacy-policy');
	};

	return (
		<Screen hideCoins>
			<View flex center>
				<View
					onPress={() =>
						auth.signInWithPopup(auth.GoogleAuthProvider)
					}>
					<Image
						source={require('../../assets/logo.png')}
						style={{ width: 100, height: 100 }}
					/>
				</View>
				<Text weight={600} size={themeConfig.fontSize.medium}>
					Telepati account
				</Text>
				{isChromeExtension && <SignUpChrome />}
				{!isChromeExtension && (
					<>
						<Text
							paddingTop={10}
							paddingHorizontal={20}
							size={themeConfig.fontSize.small}>
							If this is your first time, we will create your
							account automatically
						</Text>

						<View marginTop={20}>
							<View
								borderRadius={themeConfig.roundness.medium}
								paddingHorizontal={20}
								paddingVertical={10}
								backgroundColor={
									themeConfig.paletteScheme.background
										.accentMelow
								}
								row
								onPress={
									isChromeExtension
										? auth.signInWithPopup
										: onGoogleButtonPress
								}
								alignItems="center">
								<AntDesign
									size={themeConfig.fontSize.xxLarge * 1.5}
									name="google"
									color={
										themeConfig.paletteScheme.text.primary
									}
								/>
								<Text
									paddingTop={5}
									paddingHorizontal={10}
									size={themeConfig.fontSize.medium}>
									Connect using Google
								</Text>
							</View>
							{!isAndroid && !isChromeExtension && (
								<View
									borderRadius={themeConfig.roundness.medium}
									paddingHorizontal={20}
									paddingVertical={15}
									backgroundColor={
										themeConfig.paletteScheme.background
											.accentMelow
									}
									row
									onPress={onAppleButtonPress}
									marginTop={20}>
									<AntDesign
										style={{ marginTop: -5 }}
										size={
											themeConfig.fontSize.xxLarge * 1.5
										}
										name="apple1"
										color={
											themeConfig.paletteScheme.text
												.primary
										}
									/>
									<Text
										paddingTop={5}
										paddingHorizontal={10}
										size={themeConfig.fontSize.medium}>
										Connect using Apple ID
									</Text>
								</View>
							)}
						</View>
					</>
				)}
			</View>
			<Text textAlign="center">
				To use Telepati as a pro user, you need to have an account. By
				connecting your social account, you agreed to share your email
				and name with us
			</Text>
			<View onPress={onPrivacyClick}>
				<Text
					paddingVertical={10}
					color={themeConfig.paletteScheme.text.accent}
					textAlign="center">
					Review our Privacy Policy
				</Text>
			</View>
		</Screen>
	);
}
