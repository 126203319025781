import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { TabsNavigation } from './TabsNavigation';
import { useSyncNavigationBarColor } from './useSyncNavigationBarColor';
import { useProfileUpdate } from '../profile/useProfileUpdate';
import { useProfile } from '../profile/useProfile';
import { isChromeExtension, isWeb } from '../../theme/themeConfig';
import { SignInScreen } from '../signIn/SignInScreen';
import { AdScreen } from '../admob/AdScreen';
import { useIap } from '../iap/useIap';
import { BuyCoinsScreen } from '../billing/BuyCoinsScreen';
import { useChatsSync } from '../Chat/useChatsSync';
import { useCoinsSync } from '../billing/useCoinsSync';
import { TransactionsScreen } from '../transactions/TransactionsScreen';
import { PersonaSelector } from '../personas/PersonaSelector';
import { PersonaAddScreen } from '../personas/PersonaAddScreen';
import { RegisterChromeExtensionScreen } from '../ChromeExtension/RegisterChromeExtensionScreen';

const { Navigator, Screen } = createNativeStackNavigator();

export function MainNavigation({ user }) {
	useSyncNavigationBarColor();
	useProfileUpdate(user);
	const profile = useProfile();
	useIap(profile);
	useChatsSync(profile);
	useCoinsSync(profile);
	return (
		<Navigator screenOptions={{ headerShown: false }}>
			{!!profile?.uid && (
				<Screen
					name="Tabs"
					options={{ title: 'Telepati' }}
					component={TabsNavigation}
				/>
			)}
			{!profile?.uid && <Screen name="SignIn" component={SignInScreen} />}
			{!(isWeb || isChromeExtension) && (
				<Screen name="AdScreen" component={AdScreen} />
			)}
			<Screen name="BuyCoins" component={BuyCoinsScreen} />
			<Screen name="Transactions" component={TransactionsScreen} />
			<Screen name="PersonaSelector" component={PersonaSelector} />
			<Screen name="PersonaAddScreen" component={PersonaAddScreen} />
			<Screen
				name="RegisterChromeExtensionScreen"
				component={RegisterChromeExtensionScreen}
			/>
		</Navigator>
	);
}
