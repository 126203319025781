import React, { useEffect, useState } from 'react';
import { Alert, Linking } from 'react-native';
import { useMediaQuery } from 'react-responsive';
import { useNavigation } from '@react-navigation/native';
import { useProfile } from './useProfile';
import { PaywallScreen } from '../iap/PaywallScreen';
import { Screen } from '../../theme/Screen';
import { Text, View } from '../../theme';
import { Button } from '../../theme/Button';
import { analytics, auth, firestore } from '../../firebase';
import { useAnalytics } from '../../firebase/useAnalytics';
import { ProfileMenuItem } from './ProfileMenuItem';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { CoinIcon } from '../billing/CoinIcon';

export function ProfileScreen() {
	const profile = useProfile();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1224px)',
	});
	const navigation = useNavigation();

	const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	useAnalytics('ProfileScreen', !!profile?.uid);
	const [billingDate, setBillingDate] = useState('');

	useEffect(() => {
		const getSettings = async () => {
			const settings = await firestore
				.collection('settings')
				.doc('general')
				.get();
			const sData = settings.data();
			setBillingDate(sData?.startBilling || '');
		};
		getSettings();
	}, []);

	if (!profile?.uid) return <PaywallScreen />;
	const handleDeleteUser = async () => {
		await analytics.logEvent('DeleteAccount');
		await firestore
			.collection('history')
			.doc(profile.uid)
			.set({ chats: [] });
		await firestore
			.collection('users')
			.doc(profile.uid)
			.set({ ...profile, globalSignOut: true });
	};

	const showDeleteProfileAlert = () => {
		if (isWeb) {
			if (
				confirm(
					'You are about to delete you account. You can undo this action and it will erase your account and chat histories',
				) === true
			) {
				handleDeleteUser();
			}
		} else
			Alert.alert(
				'Oh no...!',
				'You are about to delete you account. You can undo this action and it will erase your account and chat histories',
				[
					{
						text: 'I want to delete my account',
						onPress: handleDeleteUser,
					},
					{ text: 'Cancel' },
				],
			);
	};

	return (
		<Screen>
			<View flex>
				<Text size={themeConfig.fontSize.medium}>Welcome</Text>
				<Text weight={600} size={themeConfig.fontSize.medium}>
					{profile?.displayName ||
						profile?.email ||
						'Telepati pro user'}
				</Text>
				<Text
					weight={400}
					size={themeConfig.fontSize.xSmall}
					marginBottom={20}>
					{profile?.email}
				</Text>

				<ProfileMenuItem
					icon={<CoinIcon />}
					iconType="custom"
					onPress={() => navigation.navigate('Transactions')}
					label="Transactions"
				/>
				<ProfileMenuItem
					icon="policy"
					iconType="MaterialIcons"
					onPress={() =>
						Linking.openURL('https://telepati.info/privacy-policy')
					}
					label="Privacy Policy"
				/>
				{!isChromeExtension && !isWeb && (
					<ProfileMenuItem
						icon="chrome"
						iconType="AntDesign"
						onPress={() =>
							navigation.navigate('RegisterChromeExtensionScreen')
						}
						label="Register Chrome Extension"
					/>
				)}
				<ProfileMenuItem
					icon="documents"
					iconType="Ionicons"
					onPress={() =>
						Linking.openURL('https://telepati.info/terms')
					}
					label="Terms & Conditions"
				/>

				{!isChromeExtension && (
					<ProfileMenuItem
						iconContainerColor={
							themeConfig.palette.notification.errorDisabled
						}
						iconColor={themeConfig.palette.notification.errorIcon}
						icon="delete"
						iconType="MaterialCommunityIcons"
						onPress={showDeleteProfileAlert}
						label="Delete account"
					/>
				)}
				<Button
					marginVertical={20}
					onPress={() => auth.signOut()}
					label="Logout"
				/>
				<View center>
					{/* {themeConfig.colorScheme === 'dark' && ( */}
					{/*	<Image */}
					{/*		source={require('../../assets/logo-hor-light.png')} */}
					{/*		resizeMode="contain" */}
					{/*		style={{ */}
					{/*			width: */}
					{/*				isWeb && (isDesktopOrLaptop || isBigScreen) */}
					{/*					? themeConfig.dimensions.screenWidth / 8 */}
					{/*					: themeConfig.dimensions.screenWidth / */}
					{/*					  2, */}
					{/*			height: themeConfig.dimensions.screenWidth / 8, */}
					{/*		}} */}
					{/*	/> */}
					{/* )} */}
					{/* {themeConfig.colorScheme === 'light' && ( */}
					{/*	<Image */}
					{/*		source={require('../../assets/logo-hor-dark.png')} */}
					{/*		resizeMode="contain" */}
					{/*		style={{ */}
					{/*			width: */}
					{/*				isWeb && (isDesktopOrLaptop || isBigScreen) */}
					{/*					? themeConfig.dimensions.screenWidth / 8 */}
					{/*					: themeConfig.dimensions.screenWidth / */}
					{/*					  2, */}
					{/*			height: themeConfig.dimensions.screenWidth / 8, */}
					{/*		}} */}
					{/*	/> */}
					{/* )} */}
					{/* <Text */}
					{/*	textAlign="center" */}
					{/*	color={themeConfig.paletteScheme.text.disabled}> */}
					{/*	v{pkinfo.version} */}
					{/* </Text> */}
				</View>
			</View>
		</Screen>
	);
}
