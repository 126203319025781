import Toast from 'react-native-toast-message';
import { auth } from './index';
import { auth as authInstance } from './fb';

export async function onAppleButtonPress() {
	const provider = new authInstance.OAuthProvider('apple.com');
	provider.addScope('email');
	provider.addScope('name');

	auth.signInWithPopup(provider)
		.then(() => {
			console.info(
				'USER LOGGED IN YOU SHOULD GET IT FROM onAuthStateChanged',
			);
		})
		.catch(error => {
			const errorMessage = error.message;
			Toast.show({ text1: 'Login Failed', text2: errorMessage });
		});
}

export async function onGoogleButtonPress() {
	const provider = new authInstance.GoogleAuthProvider();
	auth.signInWithPopup(provider)
		.then(() => {
			console.info(
				'USER LOGGED IN YOU SHOULD GET IT FROM onAuthStateChanged',
			);
		})
		.catch(error => {
			const errorMessage = error.message;
			Toast.show({ text1: 'Login Failed', text2: errorMessage });
		});
}
