import React from 'react';
import { FlatList } from 'react-native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { ExploreItem } from './ExploreItem';

export function ExploreHorizontalList({ item: category }) {
	const renderItem = ({ item }) => <ExploreItem item={item} />;
	return (
		<View>
			<Text
				size={themeConfig.fontSize.large}
				weight={themeConfig.fontWeight.bold}
				paddingLeft={20}
				paddingTop={30}>
				{category.title}
			</Text>
			<FlatList
				showsHorizontalScrollIndicator={false}
				ListHeaderComponent={() => <View width={20} />}
				ListFooterComponent={() => <View width={20} />}
				ItemSeparatorComponent={() => <View width={10} />}
				data={(category?.prompts || []).filter(
					item => item?.enable && item?.prompt,
				)}
				horizontal
				renderItem={renderItem}
			/>
		</View>
	);
}
