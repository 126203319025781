import { useCallback, useEffect, useState } from 'react';
import { auth } from './index';
import { useGoogleAuth } from './useGoogleAuth';

export const useAuthentication = () => {
	useGoogleAuth();
	const [initializing, setInitializing] = useState(true);
	const [user, setUser] = useState();

	// Handle user state changes
	const handleAuthChange = useCallback(
		info => {
			const isAnonymous =
				info?.isAnonymous &&
				info.providerData.every(
					userInfo => userInfo.providerId !== 'anonymous',
				);
			!isAnonymous && setUser(info);
			if (initializing) setInitializing(false);
		},
		[initializing],
	);

	useEffect(() => {
		const subscriber = auth.onAuthStateChanged(handleAuthChange);
		return subscriber; // unsubscribe on unmount
	}, [handleAuthChange]);

	return { user, initializing };
};
