import React from 'react';
import { MaterialIcons } from '@expo/vector-icons';
import { Button } from '../../theme/Button';

function saveImageAs(url, fileName) {
	// Create an anchor element
	const anchor = document.createElement('a');

	// Set the anchor's href to the image URL
	anchor.href = url;

	// Set the download attribute to specify the desired file name
	anchor.download = fileName;

	anchor.target = '_blank';

	// Trigger a click event on the anchor element
	anchor.click();
}

export function SaveButton({ url, ...props }) {
	return (
		<Button
			onPress={() =>
				saveImageAs(url, `Telepati-${new Date().getTime()}.png`)
			}
			{...props}
			icon={<MaterialIcons size={17} name="save-alt" />}
			iconOnly
		/>
	);
}
