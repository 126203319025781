import React from 'react';
import {
	AntDesign,
	Entypo,
	EvilIcons,
	Feather,
	FontAwesome,
	Fontisto,
	Foundation,
	Ionicons,
	MaterialCommunityIcons,
	MaterialIcons,
	Octicons,
	SimpleLineIcons,
	Zocial,
} from '@expo/vector-icons';

export function Icon({ name, type, size, color, ...props }) {
	const formalizedName = name?.trim()?.replace(' ', '')?.replace('\t', '');

	const formalizedType = type?.trim()?.replace(' ', '')?.replace('\t', '');
	switch (formalizedType) {
		case 'AntDesign':
			return (
				<AntDesign
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Entypo':
			return (
				<Entypo
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'EvilIcons':
			return (
				<EvilIcons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Feather':
			return (
				<Feather
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'FontAwesome':
			return (
				<FontAwesome
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Fontisto':
			return (
				<Fontisto
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Foundation':
			return (
				<Foundation
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Ionicons':
			return (
				<Ionicons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'MaterialCommunityIcons':
			return (
				<MaterialCommunityIcons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'MaterialIcons':
			return (
				<MaterialIcons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Octicons':
			return (
				<Octicons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'Zocial':
			return (
				<Zocial
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		case 'SimpleLineIcons':
			return (
				<SimpleLineIcons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
		default:
			return (
				<Ionicons
					name={formalizedName}
					size={size}
					color={color}
					{...props}
				/>
			);
	}
}
