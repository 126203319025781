import React from 'react';
import { Text as RNText } from 'react-native';
import { DotIndicator } from 'react-native-indicators';
import { View } from './View';
import { themeConfig } from './themeConfig';
import { Text } from './Text';

export function Button({
	onPress,
	size = themeConfig.fontSize.small,
	label,
	variant = 'primary',
	backgroundColor,
	iconOnly,
	icon,
	color,
	weight = 500,
	style,
	loading,
	...props
}) {
	const theme = {
		primary: {
			backgroundColor:
				backgroundColor ||
				themeConfig.paletteScheme.background.accentMelow,
			color: color || themeConfig.paletteScheme.text.accent,
		},
		error: {
			color: color || themeConfig.paletteScheme.text.negative,
			backgroundColor: backgroundColor || 'transparent',
		},
		info: {
			backgroundColor:
				backgroundColor ||
				themeConfig.paletteScheme.background.disabled,
			color: color || themeConfig.paletteScheme.text.disabled,
		},
	};
	return (
		<View
			{...props}
			onPress={!loading && onPress}
			style={{ color: theme[variant]?.color, ...style }}
			paddingTop={10}
			paddingBottom={10}
			center
			paddingHorizontal={10}
			borderRadius={10}
			backgroundColor={theme[variant]?.backgroundColor}>
			{!!loading && (
				<DotIndicator
					count={3}
					color={themeConfig.paletteScheme.text.accent}
					size={iconOnly ? 4 : 6}
				/>
			)}
			{!loading && icon && (
				<RNText
					style={{
						paddingVertical: 0,
						textAlign: 'center',
						textAlignVertical: 'center',
						color: theme[variant]?.color,
					}}>
					{icon}
				</RNText>
			)}
			{!loading && !iconOnly && (
				<Text size={size} weight={weight} color={theme[variant]?.color}>
					{label}
				</Text>
			)}
		</View>
	);
}
