import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSignIn, userSignOut, userUpdate } from '../redux/actions';
import { analytics, auth, firestore } from '../../firebase';
import { isWeb } from '../../theme/themeConfig';

export const useProfileUpdate = user => {
	const dispatch = useDispatch();
	useEffect(() => {
		const sync = async () => {
			if (user?.uid) {
				dispatch(userSignIn(user));
				await firestore
					.collection('users')
					.doc(user.uid)
					.set({
						uid: user?.uid || '',
						displayName: user?.displayName || '',
						email: user?.email || '',
						globalSignOut: false,
					});
				!isWeb && !!user?.uid && (await analytics.setUserId(user.uid));
				await analytics.setUserProperties({
					uid: user?.uid || '',
					displayName: user?.displayName || '',
					email: user?.email || '',
				});
			} else {
				dispatch(userSignOut());
			}
		};
		sync();
	}, [dispatch, user]);

	useEffect(() => {
		if (!user?.uid) return;
		const subscriber = firestore
			.collection('users')
			.doc(user.uid)
			.onSnapshot(documentSnapshot => {
				const data = documentSnapshot?.data();
				if (!data?.globalSignOut) {
					dispatch(userUpdate(data));
				} else {
					auth.signOut().then(() => {
						dispatch(userSignOut());
					});
				}
			});

		// Stop listening for updates when no longer required
		return () => subscriber();
	}, [dispatch, user]);
};
