import React from 'react';
import { Screen } from '../../theme/Screen';
import { useBilling } from '../billing/useBilling';
import { ScrollView } from '../../theme/ScrollView';
import { BuyCoinItem } from '../billing/BuyCoinItem';
import { BackButton } from '../../theme/BackButton';

export function TransactionsScreen() {
	const { transactions } = useBilling();
	return (
		<Screen>
			<BackButton />

			<ScrollView>
				{transactions.map((product, index) => {
					if (product?.amount) {
						return (
							<BuyCoinItem
								key={index}
								amount={parseInt(product?.amount, 10)}
								label={
									product?.description?.includes('Welcome') ||
									product?.description?.includes('Watched') ||
									product?.description?.includes('Started') ||
									product?.description?.includes('Generated')
										? `${product?.description}`
										: `Payment, ID ${product?.description}`
								}
								sublabel={product?.date}
							/>
						);
					}
				})}
			</ScrollView>
		</Screen>
	);
}
