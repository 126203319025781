import { isIos } from '../../theme/themeConfig';

export const IAP_KEY = isIos
	? 'appl_dyKbOzEcUEpKNrlcFqdTpLOvwGD'
	: 'goog_HzuaWKYMEIDQQVVzdMdJhPpmvQl';

export const subscriptionInfoProviders = {
	revenuecat: 'revenuecat',
	firebase: 'firebase',
};

export const ENTITLEMENT_ID = 'pro';
