import React, { useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { ScrollView } from '../../theme/ScrollView';
import { TextInput } from '../../theme/TextInput';
import { analytics, auth, crashlytics } from '../../firebase';
import { personaTelepati } from './config';
import { Button } from '../../theme/Button';

export function PersonaAddStepTwo({ onNext, pending, ...props }) {
	const [info, setInfo] = useState({ sex: 'Woman' });
	const [prompt, setPrompt] = useState('');
	const [sending, setSending] = useState(false);
	const handleChange = (key, value) =>
		setInfo(prevState => ({ ...prevState, [key]: value }));
	const [token, setToken] = useState('');
	const user = auth.currentUser;
	useEffect(() => {
		user?.getIdToken().then(tk => {
			setToken(tk);
		});
	}, [user]);
	const handleSend = async () => {
		setPrompt('');
		if (!info?.name || !info?.age || !info?.details) {
			Toast.show({
				type: 'error',
				text1: 'Hmmm!',
				text2: 'Please fill all fields in the form',
			});
			return;
		}
		const config = {
			role: 'system',
			content: personaTelepati.prompt,
		};
		const content = `Craft a prompt I can use to make ChatGPT assumes that has a new character. Name of chatgpt should be ${info.name}. also, the character of chatgpt is a ${info.sex} and has ${info.age} years old. this is more details about the chatgpt's character: ${info?.details} Please expand the prompt to make ChatGPT's new character as realistic as it can. Please only return prompt in the response, not any greetings or anything else`;

		const msg = { role: 'user', content };

		const messages = [config, msg];
		setSending(true);

		fetch(
			'https://us-central1-telepati-f930a.cloudfunctions.net/authorizedHello',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
				},
				body: JSON.stringify({
					model: 'gpt-3.5-turbo',
					messages,
				}),
			},
		)
			.then(response => response.json())
			.then(data => {
				const answersArr =
					data?.choices[0]?.message?.content?.split('"');
				const answer = (
					answersArr.length === 1
						? data?.choices[0]?.message.content
						: answersArr[1]
				).replace('Prompt:', '');
				setPrompt(answer);
				analytics.logEvent('promptGenerated', { answer });
				setSending(false);
				onNext({
					...pending,
					title: info.name,
					description: `A ${info.age} years old ${info.sex}.\n${info.details}`,
					prompt: `I am ${info.name}. ${answer}`,
				});
			})
			.catch(error => {
				crashlytics.recordError(error, 'Chat fetch error');
				setSending(false);
			});
	};

	return (
		<>
			<ScrollView {...props}>
				<Text paddingTop={20} size={themeConfig.fontSize.xxLarge * 1.2}>
					Step 2
				</Text>
				<Text weight={600} size={themeConfig.fontSize.semiSmall}>
					Information
				</Text>
				<Text
					color={themeConfig.paletteScheme.text.disabled}
					size={themeConfig.fontSize.xSmall}>
					To make you friend realistic, it should have some
					information and a little bit of history
				</Text>
				<TextInput
					onChangeText={e => handleChange('name', e)}
					labelProps={{ paddingTop: 10 }}
					label="Name"
					placeholder="Johnna Smith"
				/>
				<TextInput
					onChangeText={e => handleChange('age', e)}
					labelProps={{ paddingTop: 10 }}
					label="Age"
					placeholder="25"
					keyboardType="numeric"
				/>
				<View
					row
					borderRadius={20}
					marginTop={20}
					overflow="hidden"
					borderWidth={1}
					borderColor={
						themeConfig.paletteScheme.background.accentMelow
					}>
					<View
						onPress={() => handleChange('sex', 'Woman')}
						flex
						center
						paddingVertical={20}
						backgroundColor={
							info?.sex === 'Woman'
								? themeConfig.paletteScheme.background
										.accentMelow
								: 'transparent'
						}>
						<Text>Woman</Text>
					</View>
					<View
						width={1}
						height="100%"
						backgroundColor={
							themeConfig.paletteScheme.background.accentMelow
						}
					/>
					<View
						onPress={() => handleChange('sex', 'Man')}
						flex
						center
						paddingVertical={20}
						backgroundColor={
							info?.sex === 'Man'
								? themeConfig.paletteScheme.background
										.accentMelow
								: 'transparent'
						}>
						<Text>Man</Text>
					</View>
					<View
						width={1}
						height="100%"
						backgroundColor={
							themeConfig.paletteScheme.background.accentMelow
						}
					/>
					<View
						onPress={() => handleChange('sex', 'Transgender')}
						flex
						center
						paddingVertical={20}
						backgroundColor={
							info?.sex === 'Transgender'
								? themeConfig.paletteScheme.background
										.accentMelow
								: 'transparent'
						}>
						<Text>Transgender</Text>
					</View>
					<View
						width={1}
						height="100%"
						backgroundColor={
							themeConfig.paletteScheme.background.accentMelow
						}
					/>
					<View
						onPress={() => handleChange('sex', 'Non-binary')}
						flex
						center
						paddingVertical={20}
						backgroundColor={
							info?.sex === 'Non-binary'
								? themeConfig.paletteScheme.background
										.accentMelow
								: 'transparent'
						}>
						<Text>Non-binary</Text>
					</View>
				</View>
				<TextInput
					onChangeText={e => handleChange('details', e)}
					multiline
					labelProps={{ paddingTop: 10 }}
					label="More details"
					placeholder="She is my favorite actress and won 2 Oscar Awards."
				/>
			</ScrollView>
			<Button
				marginTop={50}
				loading={sending}
				label="Build"
				onPress={handleSend}
			/>
		</>
	);
}
