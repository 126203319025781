import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';

function QrCodeFrame({
	width = 1094,
	height = 1139,
	color = themeConfig.paletteScheme.text.primary,
}) {
	return (
		<View
			width={width}
			height={height}
			paddingHorizontal={themeConfig.pads.horizontalPad}
			paddingVertical={themeConfig.pads.horizontalPad}>
			<Svg width="100%" height="100%" viewBox="0 0 1094 1139" fill="none">
				<Path fill={color} d="M0 5H5V369H0z" />
				<Path
					transform="rotate(-90 0 5)"
					fill={color}
					d="M0 5H5V369H0z"
				/>
				<Path
					transform="rotate(-90 5 1139)"
					fill={color}
					d="M5 1139H10V1503H5z"
				/>
				<Path
					transform="rotate(-180 5 1139)"
					fill={color}
					d="M5 1139H10V1503H5z"
				/>
				<Path
					transform="rotate(-180 1089 1134)"
					fill={color}
					d="M1089 1134H1094V1498H1089z"
				/>
				<Path
					transform="rotate(90 1089 1134)"
					fill={color}
					d="M1089 1134H1094V1498H1089z"
				/>
				<Path
					transform="rotate(90 1089 5)"
					fill={color}
					d="M1089 5H1094V369H1089z"
				/>
				<Path fill={color} d="M1089 5H1094V369H1089z" />
			</Svg>
		</View>
	);
}

export default QrCodeFrame;
