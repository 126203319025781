import React from 'react';
import { ScrollView as RNScrollview } from 'react-native';

export function ScrollView({
	children,
	flex,
	center,
	backgroundColor,
	style,
	justifyContent,
	alignItems,
	paddingTop,
	paddingBottom,
	paddingLeft,
	paddingRight,
	paddingVertical,
	paddingHorizontal,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	marginVertical,
	marginHorizontal,
	width,
	height,
	flexGrow,
	contentContainerStyle,
	...props
}) {
	const styleProp = {
		justifyContent: (!!center && 'center') || justifyContent || undefined,
		alignItems: (!!center && 'center') || alignItems || undefined,
		paddingTop,
		paddingBottom,
		paddingLeft,
		paddingRight,
		paddingVertical,
		paddingHorizontal,
		marginTop,
		marginBottom,
		marginLeft,
		marginRight,
		marginVertical,
		marginHorizontal,
		width,
		height,
		backgroundColor,
		flex: flex ? 1 : undefined,
		...style,
	};

	const contentContainerStyleProp = {
		flexGrow: flexGrow ? 1 : undefined,
		...contentContainerStyle,
	};
	return (
		<RNScrollview
			showsVerticalScrollIndicator={false}
			{...props}
			contentContainerStyle={contentContainerStyleProp}
			style={styleProp}>
			{children}
		</RNScrollview>
	);
}
