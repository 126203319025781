import React from 'react';
import { TouchableOpacity } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useMediaQuery } from 'react-responsive';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { View } from '../../theme';
import { navIcons } from './config';
import { useProfile } from '../profile/useProfile';

function TabsNavigationBar({ state, descriptors, navigation, ...props }) {
	const profile = useProfile();
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-width: 1224px)',
	});
	const insets = useSafeAreaInsets();
	const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
	return (
		<View
			row
			paddingHorizontal={
				isWeb && (isBigScreen || isDesktopOrLaptop)
					? themeConfig.dimensions.screenWidth / 3
					: undefined
			}
			alignItems="center"
			justifyContent="space-between"
			center
			{...props}
			style={{
				paddingVertical:
					isWeb || isChromeExtension ? 24 : insets.bottom,
				backgroundColor: themeConfig.paletteScheme.background.primary,
			}}>
			{state.routes.map((route, index) => {
				const { options } = descriptors[route.key];
				const focused = state.index === index;
				const onPress = () => {
					const event = navigation.emit({
						type: 'tabPress',
						target: route.key,
						canPreventDefault: true,
					});

					if (!focused && !event.defaultPrevented) {
						navigation.navigate(route.name);
					}
				};

				const onLongPress = () => {
					navigation.emit({
						type: 'tabLongPress',
						target: route.key,
					});
				};

				return (
					<TouchableOpacity
						style={{ flex: 1 }}
						key={index}
						accessibilityRole="button"
						accessibilityState={focused ? { selected: true } : {}}
						accessibilityLabel={options.tabBarAccessibilityLabel}
						testID={options.tabBarTestID}
						onPress={onPress}
						onLongPress={onLongPress}>
						<View center>
							<View
								borderRadius={3}
								width={30}
								marginBottom={3}
								height={5}
								backgroundColor={
									focused
										? themeConfig.paletteScheme.background
												.accentMelow
										: 'transparent'
								}
							/>
							<MaterialCommunityIcons
								name={
									route.key.split('-')[0].toLowerCase() ===
										'profile' && !!profile?.uid
										? 'account'
										: navIcons[
												route.key
													.split('-')[0]
													.toLowerCase()
										  ]
								}
								color={
									focused
										? themeConfig.paletteScheme.text.accent
										: themeConfig.paletteScheme.text
												.disabled
								}
								size={themeConfig.fontSize.semiLarge}
							/>
							{/* <View> */}
							{/*	<Text */}
							{/*		paddingTop={5} */}
							{/*		color={ */}
							{/*			focused */}
							{/*				? themeConfig.paletteScheme.text */}
							{/*						.accent */}
							{/*				: themeConfig.paletteScheme.text */}
							{/*						.disabled */}
							{/*		} */}
							{/*		size={themeConfig.fontSize.semiSmall} */}
							{/*		weight={ */}
							{/*			focused */}
							{/*				? themeConfig.fontWeight.bold */}
							{/*				: themeConfig.fontWeight.normal */}
							{/*		}> */}
							{/*		{route.name} */}
							{/*	</Text> */}
							{/* </View> */}
						</View>
					</TouchableOpacity>
				);
			})}
		</View>
	);
}

export default TabsNavigationBar;
