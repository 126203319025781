import React, { useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import { persistor, store } from '../redux/store';
import { ToastLoactor } from '../notifications/ToastLoactor';
import { MainNavigation } from '../navigation/MainNavigation';
import { themeConfig } from '../../theme/themeConfig';
import { useAuthentication } from '../../firebase/useAuthentication';

export default function App() {
	const { user } = useAuthentication();
	const [fontsLoaded] = useFonts({
		[themeConfig.fonts
			.IRS]: require('../../assets/fonts/IRANSansMobile.ttf'),
		[themeConfig.fonts
			.IRS_Bold]: require('../../assets/fonts/IRANSansMobile_Bold.ttf'),
		[themeConfig.fonts
			.IRS_Light]: require('../../assets/fonts/IRANSansMobile_Light.ttf'),
		[themeConfig.fonts
			.IRS_UltraLight]: require('../../assets/fonts/IRANSansMobile_UltraLight.ttf'),
		[themeConfig.fonts
			.IRS_Medium]: require('../../assets/fonts/IRANSansMobile_Medium.ttf'),
	});
	useEffect(() => {}, [fontsLoaded]);

	return (
		<SafeAreaProvider>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<NavigationContainer>
						<MainNavigation user={user} />
					</NavigationContainer>
					<ToastLoactor />
				</PersistGate>
			</Provider>
		</SafeAreaProvider>
	);
}
