import React, { useState } from 'react';
import { StyleSheet, TextInput as MTI } from 'react-native';
import { isIos, isWeb, themeConfig } from './themeConfig';
import { Text } from './Text';

export function TextInput({
	style = {},
	marginTop,
	marginLeft,
	flex,
	marginRight,
	marginBottom,
	shine,
	marginHorizontal,
	marginVertical,
	label,
	labelProps,
	...props
}) {
	const [focused, setFocused] = useState(false);
	const styledProps = {
		marginLeft,
		marginRight,
		marginTop,
		flex: flex ? 1 : undefined,
		marginBottom,
		borderColor:
			shine || focused
				? themeConfig.paletteScheme.background.accent
				: themeConfig.paletteScheme.background.accentMelow,
		marginHorizontal,
		marginVertical,
		...style,
	};
	if (isWeb) {
		styledProps.outlineColor = 'transparent';
		styledProps.outlineWidth = 0;
	}
	return (
		<>
			{!!label && <Text {...labelProps}>{label}</Text>}
			<MTI
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				underlineColorAndroid="transparent"
				placeholderTextColor={themeConfig.paletteScheme.text.disabled}
				{...props}
				style={{ ...styles.input, ...styledProps }}
			/>
		</>
	);
}

const styles = StyleSheet.create({
	input: {
		minHeight: 40,
		color: themeConfig.paletteScheme.text.primary,
		marginRight: 10,
		borderRadius: 10,
		borderWidth: 1,
		borderStyle: 'solid',
		paddingHorizontal: 10,
		paddingBottom: 5,
		paddingTop: isIos ? 10 : 5,
		maxHeight: 100,
	},
});
