import React, { useEffect } from 'react';
import { RegisterChromeExtensionCamera } from './RegisterChromeExtensionCamera';
import { auth } from '../../firebase';
import { useProfile } from '../profile/useProfile';
import { SignupScreen } from '../signup/SignupScreen';

export function RegisterChromeExtensionScreen({ ...props }) {
	const profile = useProfile();
	useEffect(() => {
		auth.signOut();
	}, []);
	if (!profile?.uid) return <SignupScreen />;
	return <RegisterChromeExtensionCamera {...props} />;
}
