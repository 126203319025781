import React from 'react';
import { Screen } from '../../theme/Screen';
import { ProfileMenuItem } from '../profile/ProfileMenuItem';

export function BuyCoinsScreen() {
	return (
		<Screen hideCoins>
			<ProfileMenuItem label="Watch Ad" icon="tv" />
		</Screen>
	);
}
