import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image } from 'react-native';
import { themeConfig } from '../../theme/themeConfig';
import { Text, View } from '../../theme';
import { CoinIcon } from '../billing/CoinIcon';
import { useActivePersonas } from './usePersonas';
import { useKeyboard } from '../keyboard/useKeyboard';

export function PersonaEmptyState({ ...props }) {
	const navigation = useNavigation();
	const activePersona = useActivePersonas();
	const { isActive, dismiss } = useKeyboard();

	return (
		<View
			onPress={() => dismiss()}
			{...props}
			width="100%"
			flex
			center
			height={themeConfig.dimensions.screenHeight / 1.5}>
			<Image
				source={activePersona.assets}
				style={{
					width: 100,
					height: 100,
					borderRadius: themeConfig.roundness.large,
				}}
			/>
			<Text size={themeConfig.fontSize.large} paddingTop={10}>
				{activePersona?.title}
			</Text>
			<View paddingHorizontal={20}>
				<Text
					color={themeConfig.paletteScheme.text.disabled}
					textAlign="center">
					Ask me anything
				</Text>
			</View>
			<View onPress={() => navigation.navigate('BuyCoins')} row>
				<CoinIcon />
				<Text size={themeConfig.fontSize.small}> -1 </Text>
			</View>
			{!isActive && (
				<View
					onPress={() => navigation.navigate('PersonaSelector')}
					center
					marginTop={themeConfig.dimensions.screenHeight / 15}>
					<View style={{ opacity: 0.2 }}>
						<Image
							source={require('./assets/images.png')}
							style={{
								width: themeConfig.dimensions.screenWidth / 2,
								height: 100,
							}}
						/>
					</View>
					<Text
						weight={700}
						style={{
							position: 'absolute',
							textAlign: 'center',
							textAlignVertical: 'center',
						}}
						color={themeConfig.paletteScheme.text.accent}>
						Build your digital friend
					</Text>
				</View>
			)}
		</View>
	);
}
