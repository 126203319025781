import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { firestore, fsInstance } from '../../firebase';
import { eraseChat } from '../redux/actions';

export const useChatsSync = user => {
	const { chats = [] } = useSelector(state => state.chatReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!user?.uid) return;
		const syncLocalToFs = async () => {
			const docRef = await firestore.collection('history').doc(user.uid);
			// console.info({ chats });
			try {
				const doc = await docRef.get();
				if (doc.exists) {
					await docRef.update({
						chats: fsInstance.FieldValue.arrayUnion(...chats),
					});
					dispatch(eraseChat());
				} else {
					await docRef.set({ chats });
					dispatch(eraseChat());
				}
			} catch ({ message }) {
				console.info({ message });
			}
		};
		syncLocalToFs();
	}, [dispatch, user]);
};
