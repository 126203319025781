import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from '../home/Home';
import TabsNavigationBar from './TabsNavigationBar';
import { ChatListScreen } from '../Chat/ChatList';
import { ProfileScreen } from '../profile/ProfileScreen';
import ImageScreen from '../ImageGeneration/ImageScreen';
import { ExploreScreen } from '../explore/ExploreScreen';
import { isChromeExtension, isWeb } from '../../theme/themeConfig';

const Tab = createBottomTabNavigator();

export function TabsNavigation() {
	return (
		<Tab.Navigator
			tabBar={props => <TabsNavigationBar {...props} />}
			screenOptions={{ headerShown: false }}>
			<Tab.Screen
				name="Home"
				options={{ title: 'Telepati' }}
				component={Home}
			/>

			<Tab.Screen
				name="History"
				options={{ title: 'Telepati' }}
				component={ChatListScreen}
			/>

			<Tab.Screen
				name="Image"
				options={{ title: 'Telepati' }}
				component={ImageScreen}
			/>

			{!isWeb && !isChromeExtension && (
				<Tab.Screen
					name="Explore"
					options={{ title: 'Telepati' }}
					component={ExploreScreen}
				/>
			)}
			{/* {profile?.uid && ( */}
			{/*	<Tab.Screen */}
			{/*		name="Voice" */}
			{/*		options={{ title: 'Telepati' }} */}
			{/*		component={ImageScreen} */}
			{/*	/> */}
			{/* )} */}
			<Tab.Screen
				name="Profile"
				options={{ title: 'Telepati' }}
				component={ProfileScreen}
			/>
		</Tab.Navigator>
	);
}
