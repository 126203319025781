import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore, fsInstance } from '../../firebase';
import { reduxCoinsReset } from '../redux/actions';

export const useCoinsSync = user => {
	const { transactions = [] } = useSelector(state => state.billingReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!user?.uid) return;
		const syncLocalToFs = async () => {
			const docRef = await firestore
				.collection('transactions')
				.doc(user.uid);
			try {
				const doc = await docRef.get();
				if (doc.exists) {
					await docRef.update({
						transactions: fsInstance.FieldValue.arrayUnion(
							...transactions,
						),
					});
					dispatch(reduxCoinsReset());
				} else {
					await docRef.set({ transactions });
					dispatch(reduxCoinsReset());
				}
			} catch ({ message }) {
				console.info({ message });
			}
		};
		syncLocalToFs();
	}, [dispatch, user]);
};
