import moment from 'moment/moment';
import { BILLING_UPDATE, TRANSACTION_ADD, TRANSACTION_RESET } from '../actions';

const initState = {
	billing: {},
	transactions: [
		{
			amount: 5,
			description: 'Welcome Pack',
			date: moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
		},
	],
};
export const billingReducer = (state = initState, action) => {
	switch (action.type) {
		case TRANSACTION_ADD:
			return {
				...state,
				transactions: [...state.transactions, action.data],
			};
		case TRANSACTION_RESET:
			return {
				...state,
				transactions: [],
			};
		case BILLING_UPDATE:
			return { ...state, billing: action.data };
		default:
			return state;
	}
};
