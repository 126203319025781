import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { Text, View } from '../../theme';
import { CoinIcon } from './CoinIcon';
import { useBilling } from './useBilling';

export function Coins({ big, ...props }) {
	const { amount } = useBilling();
	const navigation = useNavigation();

	const color =
		amount < 3
			? themeConfig.paletteScheme.background.highlight
			: themeConfig.paletteScheme.text.accent;
	return (
		<View
			row
			alignItems="center"
			{...props}
			onPress={() => !big && navigation.navigate('BuyCoins')}>
			<CoinIcon color={color} marginTop={-5} />
			<Text
				color={big ? color : themeConfig.paletteScheme.text.primary}
				marginRight={10}
				paddingHorizontal={5}
				size={
					big
						? themeConfig.fontSize.xxLarge
						: themeConfig.fontSize.small
				}>
				{amount}
			</Text>
			{!big && !isChromeExtension && !isWeb && (
				<View
					row
					alignItems="center"
					paddingHorizontal={5}
					paddingVertical={3.5}
					borderRadius={5}
					backgroundColor={
						themeConfig.paletteScheme.background.accentMelow
					}>
					<Ionicons
						name="add"
						color={themeConfig.paletteScheme.text.accent}
					/>
					<Text
						paddingHorizontal={5}
						color={themeConfig.paletteScheme.text.accent}>
						Add
					</Text>
				</View>
			)}
		</View>
	);
}
