import React from 'react';
import { View } from './View';
import { themeConfig } from './themeConfig';

export function Card({
	borderRadius = themeConfig.roundness.medium,
	children,
	...props
}) {
	return (
		<View borderRadius={borderRadius} {...props}>
			{children}
		</View>
	);
}
