import React from 'react';
import { themeConfig } from '../../theme/themeConfig';
import { Card } from '../../theme/Card';
import { View } from '../../theme/View';
import { Text } from '../../theme/Text';
import { notificationTypes } from './config';

export function ToastMessage({ text1, text2, type }) {
	const accentColor =
		(type === notificationTypes.error &&
			themeConfig.palette.notification.errorIcon) ||
		(type === notificationTypes.alert &&
			themeConfig.palette.notification.warnIcon) ||
		themeConfig.paletteScheme.text.accent;

	return (
		<Card
			backgroundColor={themeConfig.paletteScheme.background.secondary}
			row
			alignItems="center"
			width="90%"
			paddingHorizontal={15}
			paddingVertical={15}>
			<View marginLeft={10}>
				<Text
					paddingRight={10}
					size={themeConfig.fontSize.small}
					color={accentColor}
					weight={700}>
					{text1}
				</Text>
				{!!text2 && (
					<Text paddingRight={35} size={themeConfig.fontSize.small}>
						{text2}
					</Text>
				)}
			</View>
		</Card>
	);
}
