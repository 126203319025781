import React from 'react';
import { Text as RNText } from 'react-native';
import { themeConfig } from './themeConfig';

export function Text({
	children,
	style,
	color = themeConfig.paletteScheme.text.primary,
	weight,
	size,
	marginVertical,
	marginHorizontal,
	paddingVertical,
	paddingHorizontal,
	marginTop,
	marginBottom,
	marginLeft,
	marginRight,
	paddingBottom,
	paddingTop,
	paddingLeft,
	textAlign,
	paddingRight,
	...props
}) {
	const additionalStyles = {
		marginVertical,
		marginHorizontal,
		paddingVertical,
		paddingHorizontal,
		marginTop,
		marginBottom,
		marginLeft,
		marginRight,
		textAlign,
		paddingBottom,
		paddingTop,
		paddingLeft,
		paddingRight,
		color,
		fontSize: style?.fontSize ? style.fontSize : size || 12,
		fontFamily: weightGenerator(weight),
		...style,
	};
	return (
		<RNText style={additionalStyles} {...props}>
			{children}
		</RNText>
	);
}
const weightGenerator = weight => {
	switch (weight) {
		case 300:
			return themeConfig.fonts.IRS_UltraLight;
		case 400:
			return themeConfig.fonts.IRS_Light;
		case 500:
			return themeConfig.fonts.IRS;
		case 600:
			return themeConfig.fonts.IRS_Medium;
		case 700:
			return themeConfig.fonts.IRS_Bold;
		default:
			return themeConfig.fonts.IRS;
	}
};
