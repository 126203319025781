import React from 'react';
import { Image } from 'react-native';
import { View } from '../../theme';
import ec from './circle.png';

export function Eclips({ size = 100, ...props }) {
	return (
		<View position="absolute" {...props}>
			<Image
				resizeMode="stretch"
				source={ec}
				style={{ width: size, height: size }}
			/>
		</View>
	);
}
