import React from 'react';
import Toast from 'react-native-toast-message';
import { ToastMessage } from './ToastMessage';
import { notificationTypes } from './config';

export function ToastLoactor() {
	const toastConfig = {
		success: props => (
			<ToastMessage type={notificationTypes.success} {...props} />
		),
		info: props => (
			<ToastMessage type={notificationTypes.success} {...props} />
		),
		error: props => (
			<ToastMessage type={notificationTypes.error} {...props} />
		),
		warning: props => (
			<ToastMessage type={notificationTypes.alert} {...props} />
		),
		alert: props => (
			<ToastMessage type={notificationTypes.alert} {...props} />
		),
	};
	return <Toast position="top" visibilityTime={6000} config={toastConfig} />;
}
