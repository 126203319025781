import { CHAT_ADD, CHAT_ERASE, CHAT_REM, CHAT_UPDATE_MSG } from '../actions';

const initState = {
	activeChat: '',
	chats: [],
};
export const chatReducer = (state = initState, action) => {
	switch (action.type) {
		case CHAT_ADD:
			return { ...state, chats: [...state.chats, action.data] };
		case CHAT_REM: {
			const oldChats = [...state.chats].filter((item, index) => {
				if (action.data?.id) {
					return item.id !== action.data.id;
				}
				return index !== action.data.index;
			});

			return { ...state, chats: oldChats };
		}
		case CHAT_ERASE:
			return { ...state, chats: [] };
		case CHAT_UPDATE_MSG: {
			const oldChats = [...state.chats];
			oldChats[action.data.index].messages = action.data.messages;
			return { ...state, chats: oldChats };
		}
		default:
			return state;
	}
};
