export const USER_SIGNIN = 'USER_SIGNIN';
export const USER_SIGNOUT = 'USER_SIGNOUT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_CHANGE = 'USER_CHANGE';
export const CHAT_LOAD = 'CHAT_LOAD'; // Select in history
export const CHAT_ADD = 'CHAT_ADD';
export const CHAT_REM = 'CHAT_REM';
export const CHAT_ERASE = 'CHAT_ERASE';
export const CHAT_UPDATE_MSG = 'CHAT_UPDATE_MSG';
export const PERSONA_ADD = 'PERSONA_ADD';
export const PERSONA_SUBMIT = 'PERSONA_SUBMIT';
export const PERSONA_SELECT = 'PERSONA_SELECT';
export const TRANSACTION_ADD = 'TRANSACTION_ADD';
export const TRANSACTION_RESET = 'TRANSACTION_RESET';
export const BILLING_UPDATE = 'BILLING_UPDATE';

export const userSignIn = data => ({
	type: USER_SIGNIN,
	data,
});
export const userUpdate = data => ({
	type: USER_UPDATE,
	data,
});
export const userSignOut = () => ({
	type: USER_SIGNOUT,
});
export const userChange = data => {
	if (typeof data !== 'object') {
		return;
	}
	return {
		type: USER_CHANGE,
		data,
	};
};
export const addChat = data => ({
	type: CHAT_ADD,
	data,
});
export const removeChat = (id = null, index = null) => ({
	type: CHAT_REM,
	data: { id, index },
});
export const eraseChat = () => ({
	type: CHAT_ERASE,
});
export const setChatMessages = (messages, id = null, index = null) => ({
	type: CHAT_UPDATE_MSG,
	data: { messages, id, index },
});

export const reduxCoinAdd = (amount, description, date = '') => ({
	type: TRANSACTION_ADD,
	data: { amount, description, date },
});

export const reduxCoinUse = (amount = 1, description = '', date = '') => ({
	type: TRANSACTION_ADD,
	data: { amount: -Math.abs(amount), description, date },
});

export const reduxCoinsReset = () => ({
	type: TRANSACTION_RESET,
});

export const reduxBillingSync = data => ({
	type: BILLING_UPDATE,
	data,
});

export const reduxPersonaAdd = data => ({
	type: PERSONA_ADD,
	data,
});

export const reduxPersonaSelect = data => ({
	type: PERSONA_SELECT,
	data,
});

export const reduxPersonaSubmit = data => ({
	type: PERSONA_SUBMIT,
	data,
});
