import React from 'react';
import { Image, Platform, StyleSheet } from 'react-native';
import Toast from 'react-native-toast-message';
import Clipboard from '@react-native-clipboard/clipboard';
import Markdown from 'react-native-markdown-display';
import { View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';

export function ChatItem({ item, ...props }) {
	const value = item?.content || '';
	const handleCopy = () => {
		Clipboard.setString(value);
		Toast.show({
			type: 'info',
			text1: 'Message copied!',
		});
	};

	if (item.role === 'system') return null;
	if (!!item?.created && !!item?.data) {
		return (
			<View onPress={handleCopy} {...props} style={styles.chatTheir}>
				<Image
					source={{ uri: item?.data[0].url }}
					style={{
						width: themeConfig.dimensions.screenWidth / 1.3,
						height: themeConfig.dimensions.screenWidth / 1.3,
					}}
				/>
			</View>
		);
	}

	return (
		<View
			onPress={handleCopy}
			{...props}
			style={item?.role === 'user' ? styles.chatYours : styles.chatTheir}>
			<Markdown style={markdownStyles}>{value}</Markdown>
			{/* <Text>{value}</Text> */}
		</View>
	);
}
const styles = StyleSheet.create({
	chatYours: {
		backgroundColor: themeConfig.paletteScheme.background.secondary,
		paddingHorizontal: 20,
		paddingBottom: 5,
		paddingTop: Platform.OS === 'ios' ? 10 : 5,
		borderTopLeftRadius: 10,
		borderBottomLeftRadius: 10,
		borderTopRightRadius: 10,
		maxWidth: '90%',
		alignSelf: 'flex-end',
	},
	chatTheir: {
		backgroundColor: themeConfig.paletteScheme.background.accentMelow,
		borderTopLeftRadius: 10,
		borderBottomRightRadius: 10,
		borderTopRightRadius: 10,
		paddingHorizontal: 20,
		paddingBottom: 5,
		paddingTop: Platform.OS === 'ios' ? 10 : 5,
		maxWidth: '90%',
		alignSelf: 'flex-start',
	},
});
const markdownStyles = StyleSheet.create({
	heading1: {
		fontSize: 32,
		backgroundColor: '#000000',
		color: '#FFFFFF',
	},
	heading2: {
		fontSize: 24,
	},
	heading3: {
		fontSize: 18,
	},
	heading4: {
		fontSize: 16,
	},
	heading5: {
		fontSize: 13,
	},
	heading6: {
		fontSize: 11,
	},
	text: {
		color: themeConfig.paletteScheme.text.primary,
		fontFamily: themeConfig.fonts.IRS,
	},
	blockquote: {
		backgroundColor: themeConfig.paletteScheme.background.disabled,
	},
	code_block: {
		backgroundColor: themeConfig.paletteScheme.background.disabled,
	},
	blocklink: {
		backgroundColor: themeConfig.paletteScheme.background.disabled,
	},
	span: {
		backgroundColor: themeConfig.paletteScheme.background.disabled,
	},
	inline: {
		backgroundColor: themeConfig.paletteScheme.background.disabled,
	},
	pre: {
		backgroundColor: themeConfig.paletteScheme.background.disabled,
	},
	fence: {
		borderColor: 'transparent',
		backgroundColor: themeConfig.paletteScheme.background.disabled,
		borderRadius: 10,
		color: themeConfig.paletteScheme.text.primary,
		marginVertical: 5,
	},
	code_inline: {
		backgroundColor: 'transparent',
		color: themeConfig.paletteScheme.text.accent,
		borderColor: 'transparent',
		paddingLeft: 5,
	},
	bullet_list: {
		color: themeConfig.paletteScheme.text.accent,
	},
	ordered_list: {
		color: themeConfig.paletteScheme.text.primary,
	},
});
