import {
	USER_CHANGE,
	USER_SIGNIN,
	USER_SIGNOUT,
	USER_UPDATE,
} from '../actions';

const initState = {
	token: '',
	locale: 'en',
};
export const profileReducer = (state = initState, action) => {
	switch (action.type) {
		case USER_SIGNIN:
			return action.data;
		case USER_UPDATE:
			return action.data;
		case USER_CHANGE:
			return { ...state, ...action.data };
		case USER_SIGNOUT:
			return initState;
		default:
			return state;
	}
};
