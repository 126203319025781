import React, { useState } from 'react';
import { Image } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import Toast from 'react-native-toast-message';
import ImageResizer from '@bam.tech/react-native-image-resizer';
import axios from 'axios';
import { DotIndicator } from 'react-native-indicators';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { Button } from '../../theme/Button';
import { person1, person2, person3, person4 } from './config';
import FadeView from '../../theme/FadeView';
import { analytics, crashlytics } from '../../firebase/index';
import { useBilling } from '../billing/useBilling';
import { useProfile } from '../profile/useProfile';
import { OPEN_AI_API_KEY } from '../openAi/config';
import { CoinIcon } from '../billing/CoinIcon';
import { ScrollView } from '../../theme/ScrollView';

export function PersonaAddStepOne({ onNext, pending, ...props }) {
	const [state, setState] = useState(pending.assets);
	const [data, setData] = useState([]);
	const [sending, setSending] = useState(false);
	const profile = useProfile();
	const { amount, spendForImage } = useBilling();
	const navigation = useNavigation();
	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		const result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: true,
			aspect: [1, 1],
			allowsMultipleSelection: false,
			quality: 0.8,
			base64: true,
		});

		if (!result.canceled) {
			try {
				const resp = await ImageResizer.createResizedImage(
					result.assets[0].uri,
					400,
					400,
					'PNG',
					100,
					0,
					undefined,
					false,
					{
						mode: 'contain',
						onlyScaleDown: false,
					},
				);
				setState({
					uri: `data:image/png;base64,${result.assets[0].base64}`,
				});
				await handleSend(resp);
			} catch (error) {
				crashlytics.recordError(error, 'Image resize error');
				console.info(error.toString());
			}
		}
	};

	const handleSend = async image => {
		if (amount < 5) {
			Toast.show({
				type: 'error',
				text1: 'Oh No!',
				text2: 'You do not have enough coins, Please add some coins',
			});
			await analytics.logEvent('CoinsFinished');
		}
		if (!image?.uri || amount < 5) return;

		try {
			setSending(true);
			const formData = new FormData();
			const imageData = {
				uri: image.uri,
				type: `image/png`,
				name: image.name,
			};
			// console.info(imageData);
			formData.append('image', imageData);
			formData.append('n', 4);
			formData.append('size', '512x512');
			formData.append('user', profile?.id);
			formData.append('response_format', 'b64_json');
			const response = await axios.post(
				'https://api.openai.com/v1/images/variations',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
						Authorization: `Bearer ${OPEN_AI_API_KEY}`,
					},
				},
			);

			const json = response.data;
			setData(json?.data || []);
			await analytics.logEvent('replyImage');
			!json?.error && (await spendForImage(5));
			!!json?.error && (await analytics.logEvent('ImageCensored'));
			setSending(false);
		} catch (error) {
			crashlytics.recordError(error, 'Image fetch error');
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				console.info(error.response.data);
				console.info(error.response.status);
				console.info(error.response.headers);
			} else if (error.request) {
				// The request was made but no response was received
				// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
				// http.ClientRequest in node.js
				console.info(JSON.stringify(error.request, undefined, 2));
			} else {
				// Something happened in setting up the request that triggered an Error
				console.info('Error', error.message);
			}
			setSending(false);
		}
	};
	return (
		<ScrollView contentContainerStyle={{ flex: 1 }} {...props} flex>
			<Text paddingTop={20} size={themeConfig.fontSize.xxLarge * 1.2}>
				Step 1
			</Text>
			<Text weight={600} size={themeConfig.fontSize.semiSmall}>
				How does it look like?
			</Text>
			<Text
				color={themeConfig.paletteScheme.text.disabled}
				size={themeConfig.fontSize.xSmall}>
				Let&apos;s draw the picture of your new friend. Upload an image
				first.
			</Text>
			<View center flex>
				<View>
					<Image
						source={state}
						style={{
							width: 200,
							height: 200,
							marginBottom: 10,
							borderRadius: themeConfig.roundness.xLarge,
							borderColor: themeConfig.paletteScheme.text.accent,
							borderWidth: 1,
						}}
					/>
					{!!sending && (
						<View
							position="absolute"
							alignSelf="center"
							borderRadius={themeConfig.roundness.xLarge}
							borderWidth={1}
							center
							borderColor={themeConfig.paletteScheme.text.accent}
							height={200}
							width={200}
							backgroundColor={
								themeConfig.paletteScheme.background.accentMelow
							}
							justifyContent="flex-start"
							alignItems="flex-start"
							marginBottom={10}>
							<DotIndicator
								count={3}
								size={10}
								color={themeConfig.paletteScheme.text.accent}
							/>
						</View>
					)}
				</View>

				<Button onPress={pickImage} label="Upload" />
				<View row marginTop={5}>
					<CoinIcon size={themeConfig.fontSize.xxSmall} />
					<Text
						color={themeConfig.paletteScheme.text.disabled}
						size={themeConfig.fontSize.xxSmall}>
						{' '}
						-4 / Upload
					</Text>
				</View>
				<View
					row
					justifyContent="space-around"
					marginVertical={10}
					width="100%">
					{data.map((item, index) => (
						<FadeView
							key={index}
							visible
							delay={300}
							duration={1000}
							onPress={() =>
								setState({
									uri: `data:image/png;base64,${item?.b64_json}`,
								})
							}>
							<Image
								source={{
									uri: `data:image/png;base64,${item?.b64_json}`,
								}}
								style={{
									width: 70,
									height: 70,
									borderRadius: themeConfig.roundness.medium,
								}}
							/>
						</FadeView>
					))}
				</View>
				<View center width="100%">
					<Text>Or select one of these faces</Text>
					<View
						row
						justifyContent="space-around"
						marginTop={10}
						width="100%">
						<FadeView
							visible
							delay={300}
							duration={1000}
							onPress={() => setState(person1)}>
							<Image
								source={person1}
								style={{
									width: 70,
									height: 70,
									borderRadius: themeConfig.roundness.medium,
								}}
							/>
						</FadeView>
						<FadeView
							visible
							delay={500}
							duration={1000}
							onPress={() => setState(person2)}>
							<Image
								source={person2}
								style={{
									width: 70,
									height: 70,
									borderRadius: themeConfig.roundness.medium,
								}}
							/>
						</FadeView>
						<FadeView
							visible
							delay={700}
							duration={1000}
							onPress={() => setState(person3)}>
							<Image
								source={person3}
								style={{
									width: 70,
									height: 70,
									borderRadius: themeConfig.roundness.medium,
								}}
							/>
						</FadeView>
						<FadeView
							visible
							delay={900}
							duration={1000}
							onPress={() => setState(person4)}>
							<Image
								source={person4}
								style={{
									width: 70,
									height: 70,
									borderRadius: themeConfig.roundness.medium,
								}}
							/>
						</FadeView>
					</View>
				</View>
			</View>
			<Button
				marginTop={5}
				label="Next"
				onPress={() => onNext({ ...pending, assets: state })}
			/>
			<Button
				onPress={() => navigation.goBack()}
				backgroundColor="transparent"
				label="Cancel"
			/>
		</ScrollView>
	);
}
