import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Image, Platform } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Text, View } from '../../theme';
import { Card } from '../../theme/Card';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { Button } from '../../theme/Button';
import { analytics } from '../../firebase';
import { useChatLogic } from './useChatLogic';
import { usePersonas } from '../personas/usePersonas';
import { personaTelepati } from '../personas/config';

export function ChatHistoryItem({
	onItemPressed,
	profile,
	item,
	index,
	...props
}) {
	const navigation = useNavigation();
	const { deleteChat } = useChatLogic();

	const handleGoHome = async () => {
		await analytics.logEvent('HistoryPressed');
		if (isWeb || isChromeExtension) {
			navigation.navigate('Home', { index });
		} else {
			navigation.navigate('AdScreen', { index });
		}
	};
	const personas = usePersonas();
	const systemMessage = item.messages.find(chat => chat?.role === 'system');
	const persona = personas.find(p => p?.prompt === systemMessage?.content);
	const insertDate =
		!!item?.insertDate && new Date(item?.insertDate).toDateString();

	const handleRemove = async () => {
		await deleteChat(index);
	};
	return (
		<Card
			onPress={handleGoHome}
			{...props}
			row
			paddingTop={Platform.OS === 'android' ? 10 : 15}
			paddingBottom={10}
			paddingLeft={10}
			alignItems="center"
			paddingRight={15}
			backgroundColor={themeConfig.paletteScheme.background.secondary}>
			<Image
				source={(persona || personaTelepati).assets}
				style={{
					borderRadius: themeConfig.roundness.small,
					width: 50,
					height: 50,
					marginRight: 10,
				}}
			/>
			<View flex paddingRight={10}>
				<Text numberOfLines={1} size={themeConfig.fontSize.small}>
					{item.messages.filter(chat => chat?.role !== 'system')[0]
						?.content || ''}
				</Text>
				{!!item?.insertDate && (
					<Text
						numberOfLines={1}
						color={themeConfig.paletteScheme.text.disabled}
						size={themeConfig.fontSize.xxSmall}>
						{insertDate}
					</Text>
				)}
			</View>
			<Button
				onPress={handleRemove}
				label={
					<MaterialCommunityIcons
						size={20}
						name="delete"
						color={themeConfig.paletteScheme.text.accent}
					/>
				}
			/>
		</Card>
	);
}
