import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useProfile } from '../profile/useProfile';
import { reduxCoinAdd, reduxCoinUse } from '../redux/actions';
import { firestore } from '../../firebase';

export const useBilling = () => {
	const [onlineTransactions, setOnlineTransactions] = useState([]);
	const profile = useProfile();
	const dispatch = useDispatch();
	const local = useSelector(state => state.billingReducer);
	const [dataSource, setDataSource] = useState('redux');
	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		if (!profile?.uid) {
			setTransactions(local?.transactions || []);
			setDataSource('redux');
		} else {
			setTransactions(onlineTransactions);
			setDataSource('firebase');
		}
	}, [local?.transactions, onlineTransactions, profile?.uid]);

	useEffect(() => {
		if (!profile?.uid) return;
		const subscriber = firestore
			.collection('transactions')
			.doc(profile.uid)
			.onSnapshot(documentSnapshot => {
				const trs = documentSnapshot?.data()?.transactions || [];
				setOnlineTransactions(trs);
			});

		// Stop listening for updates when no longer required
		return () => subscriber();
	}, [dispatch, profile.uid]);

	const spendForChat = async (tramount = 1) => {
		if (profile?.uid) {
			await fsCoinUse(
				tramount,
				'Started a chat',
				moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
			);
		} else
			dispatch(
				reduxCoinUse(
					tramount,
					'Started a chat',
					moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
				),
			);
	};

	const spendForImage = async (tramount = 2) => {
		if (profile?.uid) {
			await fsCoinUse(
				tramount,
				'Generated an image',
				moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
			);
		} else
			dispatch(
				reduxCoinUse(
					tramount,
					'Generated an image',
					moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
				),
			);
	};

	const addCoinByAd = async () => {
		if (profile?.uid) {
			await fsCoinAdd(
				1,
				'Watched an Advertisement',
				moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
			);
		} else
			dispatch(
				reduxCoinAdd(
					1,
					'Watched an Advertisement',
					moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
				),
			);
	};
	const addCoinByPayment = async (
		amount,
		description = 'Paid the product',
		date = new Date(),
	) => {
		if (!amount) return;
		if (profile?.uid) {
			await fsCoinAdd(
				amount,
				description,
				moment.utc(new Date()).format('YYYY-MM-DD HH:mm'),
			);
		} else
			dispatch(
				reduxCoinAdd(
					amount,
					description,
					moment.utc(date).format('YYYY-MM-DD HH:mm'),
				),
			);
	};

	const amount = [...transactions].reduce(
		(total = 0, item) => total + (item?.amount || 0),
		0,
	);

	const fsCoinAdd = async (
		trAmount,
		description = 'Paid the product',
		date = new Date(),
	) => {
		if (!profile?.uid) return;
		const docRef = await firestore
			.collection('transactions')
			.doc(profile.uid);
		const docData = await docRef.get();
		if (!docData.exists) return;
		const tr = docData.data();
		const newTr = [
			...tr.transactions,
			{ amount: trAmount, description, date },
		];
		await docRef.update({
			transactions: newTr,
		});
	};

	const fsCoinUse = async (
		trAmount,
		description = 'Paid the product',
		date = new Date(),
	) => {
		await fsCoinAdd(-Math.abs(trAmount), description, date);
	};

	return {
		transactions,
		spendForChat,
		addCoinByAd,
		spendForImage,
		amount,
		addCoinByPayment,
		dataSource,
	};
};
