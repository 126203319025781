import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { reduxPersonaAdd } from '../redux/actions';
import { Icon } from '../../theme/Icon';
import { UNKNOWN_BASE64 } from './config';

export function PersonaAdd({ isActive }) {
	const dispatch = useDispatch();
	const navigation = useNavigation();
	const handleAdd = () => {
		dispatch(
			reduxPersonaAdd({
				assets: { uri: UNKNOWN_BASE64 },
			}),
		);
		navigation.navigate('PersonaAddScreen');
	};
	return (
		<View
			onPress={handleAdd}
			center
			backgroundColor={
				isActive
					? themeConfig.paletteScheme.background.accent
					: 'transparent'
			}
			paddingHorizontal={10}
			borderRadius={themeConfig.roundness.large}
			paddingVertical={10}>
			<View
				width={60}
				height={60}
				center
				borderRadius={themeConfig.roundness.large}
				borderColor={themeConfig.paletteScheme.text.accent}
				borderWidth={1}>
				<Icon
					type="MaterialIcons"
					name="person"
					size={themeConfig.fontSize.xLarge * 1.5}
					color={
						isActive
							? themeConfig.paletteScheme.background.primary
							: themeConfig.paletteScheme.text.primary
					}
				/>
			</View>
			<Text
				size={themeConfig.fontSize.semiSmall}
				color={
					isActive
						? themeConfig.paletteScheme.background.primary
						: themeConfig.paletteScheme.text.primary
				}
				paddingTop={10}>
				Add
			</Text>
		</View>
	);
}
