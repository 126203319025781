import React from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { themeConfig } from '../../theme/themeConfig';
import { View } from '../../theme';

export function CoinIcon({
	color = themeConfig.paletteScheme.text.accent,
	size = 15,
	...props
}) {
	return (
		<View row {...props}>
			<FontAwesome size={size} name="circle-o" color={color} />
			<FontAwesome
				style={{ marginLeft: size - size * 1.6, marginTop: 2 }}
				size={size}
				name="circle"
				color={color}
			/>
		</View>
	);
}
