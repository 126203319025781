import React from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { EmptyState } from '../emptyState/EmptyState';
import EmptyImage from '../emptyState/EmptyImage';
import { CoinIcon } from '../billing/CoinIcon';
import { TextInput } from '../../theme/TextInput';
import { Button } from '../../theme/Button';

export function ImageForm({ handleSend, prompt, setPrompt }) {
	const navigation = useNavigation();

	return (
		<>
			<View
				width="100%"
				flex
				center
				height={themeConfig.dimensions.screenHeight / 1.3}>
				<EmptyState
					component={<EmptyImage />}
					description="Tell me about your imagination"
					actionLabel={
						<View row>
							<CoinIcon />
							<Text size={themeConfig.fontSize.small}> -2 </Text>
						</View>
					}
					materialCommunityIcon="chat"
					onPress={() => navigation.navigate('BuyCoins')}
				/>
			</View>
			<Text color={themeConfig.paletteScheme.text.accent}>
				Start with a detailed description
			</Text>
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'padding' : undefined}
				style={{
					flexDirection: 'row',
					marginTop: 10,
					alignItems: 'center',
				}}>
				<TextInput
					flex
					placeholder="A sunflower riding a horse"
					marginRight={10}
					onSubmitEditing={handleSend}
					value={prompt}
					onChangeText={setPrompt}
				/>
				<Button
					marginRight={10}
					label="Generate"
					onPress={handleSend}
					title="send"
				/>
			</KeyboardAvoidingView>
		</>
	);
}
