import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import { createLogger } from 'redux-logger';
import { reducer } from "../reducers";

const middleware = [];
// middleware.push(createLogger());

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  undefined,
  compose(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);
