import { useState } from 'react';

export const usePackages = () => {
	const [packages] = useState([]);
	const [loading] = useState(false);
	const unavailable = () => console.info('Not available in web');

	return {
		packages,
		refetch: unavailable,
		handleSelect: unavailable,
		loading,
	};
};
