import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { firebaseConfig } from './config';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
export const analytics = () => {
	const logEvent = input => {
		firebase.analytics().logEvent(input);
	};
	const logScreenView = input => {
		firebase.analytics().logEvent('screen_view', {
			firebase_screen: input.screen_name,
		});
	};
	const setUserProperties = input => {
		firebase.analytics().setUserProperties(input);
	};
	return { logEvent, logScreenView, setUserProperties };
};

export const { auth } = firebase;
export const { database } = firebase;
export const { firestore } = firebase;

export const crashlytics = () => {
	const log = input => console.info(input);
	const recordError = input => console.info(input);

	return { log, recordError };
};
