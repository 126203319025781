import React, { useEffect, useState } from 'react';
import { FlatList, Image } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Screen } from '../../theme/Screen';
import { useAnalytics } from '../../firebase/useAnalytics';
import { useActivePersonas, usePersonas } from './usePersonas';
import { PersonaItem } from './PersonaItem';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { BackButton } from '../../theme/BackButton';
import { TextInput } from '../../theme/TextInput';
import { Button } from '../../theme/Button';
import { reduxPersonaSelect } from '../redux/actions';
import { PersonaAdd } from './PersonaAdd';
import { useKeyboard } from '../keyboard/useKeyboard';

export function PersonaSelector() {
	useAnalytics('PersonaSelector');
	const navigation = useNavigation();
	const personas = usePersonas();
	const renderPersona = ({ item }) => <PersonaItem item={item} />;
	const [showPrompt, setShowPrompt] = useState(false);
	const activePersona = useActivePersonas();
	const { isActive } = useKeyboard();
	const dispatch = useDispatch();
	const [customPrompt, setCustomPrompt] = useState(
		activePersona?.prompt || '',
	);

	useEffect(() => {
		setCustomPrompt(activePersona?.prompt || '');
	}, [activePersona?.prompt]);

	return (
		<Screen hideCoins>
			<BackButton />
			<View marginHorizontal={-20} paddingTop={20}>
				<FlatList
					showsHorizontalScrollIndicator={false}
					ListHeaderComponent={() => <View width={10} height={1} />}
					ListFooterComponent={() => <PersonaAdd isActive={false} />}
					horizontal
					data={(personas || [])?.filter(item => !!item?.prompt)}
					renderItem={renderPersona}
				/>
			</View>

			<View alignItems="center" flex>
				{!isActive && (
					<Image
						source={activePersona.assets}
						style={{
							marginTop: 50,
							width: 100,
							height: 100,
							borderRadius: themeConfig.roundness.large,
						}}
					/>
				)}

				<Text size={themeConfig.fontSize.large} paddingTop={10}>
					{activePersona?.title}
				</Text>
				<View paddingHorizontal={20}>
					<Text
						size={themeConfig.fontSize.small}
						color={themeConfig.paletteScheme.text.disabled}
						textAlign="center">
						{activePersona?.description}
					</Text>
					<Button
						onPress={() =>
							navigation.navigate('AdScreen', { newChat: true })
						}
						marginTop={10}
						label={`Chat with ${activePersona.title}`}
					/>
				</View>
			</View>
			{!showPrompt && (
				<View
					onPress={() => setShowPrompt(true)}
					paddingVertical={30}
					style={{ opacity: 0.5 }}>
					<Text
						color={themeConfig.paletteScheme.text.disabled}
						textAlign="center">
						You also can review and edit prompt
					</Text>
				</View>
			)}
			{!!showPrompt && (
				<View row paddingVertical={30} alignItems="center">
					<TextInput
						onChangeText={setCustomPrompt}
						style={{
							flex: 1,
							maxHeight: themeConfig.dimensions.screenHeight / 6,
						}}
						multiline
						value={customPrompt}
					/>
					<View width={10} height={1} />
					<Button
						onPress={() => {
							setShowPrompt(false);
							!!customPrompt &&
								dispatch(
									reduxPersonaSelect({
										...activePersona,
										prompt: customPrompt,
									}),
								);
						}}
						label="Save"
					/>
				</View>
			)}
		</Screen>
	);
}
