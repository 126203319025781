import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';

export function EmptyChat(props) {
	return (
		<Svg
			width={
				isWeb || isChromeExtension
					? 200
					: themeConfig.dimensions.screenWidth / 1.5
			}
			height={
				isWeb || isChromeExtension
					? 200
					: themeConfig.dimensions.screenWidth / 1.5
			}
			viewBox="0 0 280 280"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M173.464 58.023s-29.376 37.21-76.379 15.015S3.08 110.9 43.554 147.458s.867 56.646 28.364 79.051c31.252 25.464 58.46-26.173 83.267-14.422 54.75 25.934 95.311 15.667 86.171-14.362-16.042-52.713-36.557-56.142-20.889-83.56 15.667-27.419-18.911-84.428-47.003-56.142z"
				fill={themeConfig.paletteScheme.background.accentMelow}
			/>
			<Path
				d="M123.275 174.775c0 25.267 20.48 45.747 45.747 45.747a45.436 45.436 0 0017.166-3.335c.112.014.225.02.338.02h20.023a4.898 4.898 0 004.906-4.906v-20.407a45.488 45.488 0 003.315-17.119c0-25.268-20.48-45.748-45.748-45.748-25.267 0-45.747 20.48-45.747 45.748z"
				fill={themeConfig.paletteScheme.background.secondary}
			/>
			<Path
				d="M187.334 142.348c0 31.699-25.694 57.392-57.393 57.392a57.03 57.03 0 01-21.534-4.184 3.551 3.551 0 01-.425.025h-25.12a6.145 6.145 0 01-4.353-1.802 6.143 6.143 0 01-1.802-4.353v-25.602a57.058 57.058 0 01-4.158-21.476c0-31.699 25.693-57.392 57.392-57.392s57.393 25.693 57.393 57.392z"
				fill={themeConfig.paletteScheme.background.accentMelow}
				stroke={themeConfig.paletteScheme.background.accent}
				strokeWidth={1}
			/>
			<Path
				d="M103.324 153.992a8.318 8.318 0 100-16.635 8.318 8.318 0 000 16.635zM127.445 153.992a8.318 8.318 0 100-16.635 8.318 8.318 0 000 16.635zM152.399 153.992a8.318 8.318 0 100-16.635 8.318 8.318 0 100 16.635z"
				fill="transparent"
				stroke={themeConfig.paletteScheme.background.accent}
				strokeWidth={1}
			/>
			<Path
				d="M178.184 114.899c8.268 0 14.971-6.703 14.971-14.972 0-8.269-6.703-14.972-14.971-14.972-8.269 0-14.972 6.704-14.972 14.972 0 8.269 6.703 14.972 14.972 14.972z"
				fill={themeConfig.paletteScheme.text.accent}
				stroke={themeConfig.paletteScheme.background.secondary}
				strokeWidth={15}
				strokeMiterlimit={10}
			/>
			<Path
				d="M182.942 95.49a5.65 5.65 0 00-1.039-2.09 3.883 3.883 0 00-1.62-1.157 6.445 6.445 0 00-4.205 0 3.823 3.823 0 00-1.614 1.157 5.468 5.468 0 00-1.039 2.09 11.803 11.803 0 00-.365 3.148v2.523a11.612 11.612 0 00.371 3.154c.194.769.55 1.488 1.045 2.109.43.525.988.929 1.62 1.175a6.211 6.211 0 004.193 0 3.899 3.899 0 001.608-1.175 5.7 5.7 0 001.033-2.109c.262-1.03.385-2.091.365-3.154h.012v-2.523c.021-1.061-.102-2.12-.365-3.148zm-2.257 6.048c.009.673-.045 1.346-.161 2.01a4.04 4.04 0 01-.476 1.342 1.93 1.93 0 01-.779.754 2.37 2.37 0 01-1.076.235 2.412 2.412 0 01-1.076-.235 2.017 2.017 0 01-.798-.754 4.129 4.129 0 01-.495-1.342 9.897 9.897 0 01-.173-2.01v-3.302c-.011-.672.045-1.343.167-2.004.081-.463.247-.908.489-1.311.191-.309.466-.557.791-.718a2.685 2.685 0 012.14 0c.328.157.603.406.791.718.242.403.408.848.489 1.31.122.662.178 1.333.167 2.005v3.302z"
				fill={themeConfig.paletteScheme.background.secondary}
			/>
		</Svg>
	);
}
