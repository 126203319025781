import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { themeConfig } from '../../theme/themeConfig';

function EmptyImage(props) {
	return (
		<Svg
			width={themeConfig.dimensions.screenWidth / 1.8}
			height={themeConfig.dimensions.screenWidth / 1.8}
			viewBox="0 0 226 223"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M216.079 80.709s-31.804 22.893-3.382 81.255c25.242 51.831-44.186 66.522-72.67 58.033-36.899-10.996-53.894-60.623-84.113-48.344-30.22 12.28-52.108-58.733-7.842-79.8C103.14 65.644 71.495 44.256 78.386 21.8c4.946-16.117 55.146-36.918 78.082-5.43 19.3 26.496 30.767 18.794 40.983 16.495 14.733-3.316 43.537 24.136 18.628 47.843z"
				fill={themeConfig.paletteScheme.background.accentMelow}
			/>
			<Path
				d="M213.675 144.446l-60.39-22.038c-3.203-1.166-6.699-1.12-9.72.126-3.02 1.246-5.319 3.592-6.393 6.522l-16.138 44.223c-1.066 2.932-.818 6.207.69 9.106s4.152 5.185 7.353 6.357l60.39 22.039c3.204 1.165 6.699 1.12 9.72-.127 3.021-1.246 5.32-3.592 6.393-6.521l16.138-44.223c1.067-2.933.819-6.208-.689-9.107-1.508-2.899-4.153-5.185-7.354-6.357zm-19.132 5.546a10.299 10.299 0 014.523 3.234c1.157 1.455 1.845 3.174 1.978 4.939a8.13 8.13 0 01-1.233 4.979c-.936 1.481-2.338 2.644-4.028 3.341-1.69.697-3.593.898-5.467.577a10.483 10.483 0 01-5.061-2.382c-1.426-1.232-2.453-2.811-2.951-4.538-.498-1.727-.444-3.524.154-5.164.805-2.197 2.53-3.956 4.795-4.891 2.266-.935 4.887-.969 7.29-.095zm-63.448 33.222c-1.602-.584-2.926-1.727-3.68-3.177s-.877-3.088-.342-4.554l4.263-11.683 23.213-8.031c2.238-.772 4.757-.699 7.041.204 2.285.903 4.165 2.568 5.257 4.656l8.171 15.669-29.511 12.176-14.412-5.26zm68.446 18.715c-.535 1.466-1.684 2.64-3.195 3.263-1.511.623-3.26.645-4.861.061l-37.437-13.662 30.569-12.613c2.083-.67 4.396-.615 6.528.157 2.131.772 3.943 2.211 5.115 4.061l6.443 10.068-3.162 8.665z"
				fill={themeConfig.paletteScheme.background.secondary}
			/>
			<Path
				d="M120.649 8.326L20.109 44.958c-5.33 1.948-9.734 5.751-12.247 10.575C5.35 60.357 4.934 65.81 6.706 70.691l26.825 73.625c1.784 4.878 5.61 8.784 10.636 10.861 5.027 2.077 10.846 2.156 16.179.219l100.541-36.632c5.329-1.948 9.733-5.751 12.246-10.575 2.512-4.824 2.928-10.275 1.156-15.158l-26.825-73.625c-1.784-4.878-5.609-8.784-10.636-10.861-5.027-2.077-10.845-2.156-16.179-.219zM102.22 35.891c2.983-1.087 6.194-1.34 9.227-.727 3.032.614 5.751 2.065 7.812 4.172a13.546 13.546 0 013.764 7.663c.393 2.89-.147 5.873-1.552 8.572-1.406 2.7-3.614 4.993-6.346 6.592a17.432 17.432 0 01-8.999 2.389c-3.137-.04-6.137-.952-8.621-2.619-2.484-1.666-4.341-4.013-5.336-6.743-1.33-3.662-1.018-7.75.867-11.368 1.884-3.619 5.187-6.47 9.184-7.931zM56.993 146.192c-2.667.971-5.577.933-8.092-.106-2.514-1.039-4.426-2.993-5.316-5.434L36.5 121.202l20.964-35.1c2.025-3.382 5.311-5.987 9.19-7.285 3.877-1.298 8.056-1.192 11.683.298l27.198 11.204-24.547 47.131-23.994 8.743zm107.242-49.499c.889 2.441.683 5.168-.574 7.581-1.257 2.413-3.461 4.314-6.127 5.286l-62.326 22.708 25.426-48.82c1.935-3.086 4.94-5.493 8.483-6.794 3.542-1.3 7.393-1.409 10.867-.308l18.995 5.922 5.256 14.425z"
				fill={themeConfig.paletteScheme.background.accentMelow}
				stroke={themeConfig.paletteScheme.background.accent}
				strokeWidth={1}
			/>
		</Svg>
	);
}

export default EmptyImage;
