import { Appearance, Dimensions, Platform } from 'react-native';
import pkinfo from '../../package.json';

const fonts = {
	IRS: 'IRANSansMobile',
	IRS_Bold: 'IRANSansMobile_Bold',
	IRS_Light: 'IRANSansMobile_Light',
	IRS_Medium: 'IRANSansMobile_Medium',
	IRS_UltraLight: 'IRANSansMobile_UltraLight',
};
const colorScheme = Appearance.getColorScheme();

export const isWeb = Platform.OS === 'web' && pkinfo.env !== 'chrome';
export const isChromeExtension =
	Platform.OS === 'web' && pkinfo.env === 'chrome';
export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';

export const heavyOpacity = 0.7;
export const lightOpacity = 0.15;

const brand = {
	primary: '#30e2c1',
	primaryDark: '#00876e',
	primaryDisabled: `rgba(0, 135, 110, ${lightOpacity})`,
	secondary: '#FFFFFFFF',
	secondaryDisabled: `rgba(53, 130, 63, ${heavyOpacity})`,
	highlight: '#FCBA19',
	highlightDisabled: `rgba(252, 186, 25, ${lightOpacity})`,
	primaryBackground: '#000000',
	secondaryBackground: '#313132',
	link: '#FFFFFF',
};

const notification = {
	success: '#313132',
	successBorder: '#2E8540',
	successIcon: '#2E8540',
	successText: '#FFFFFF',
	info: '#313132',
	infoBorder: '#0099FF',
	infoIcon: '#0099FF',
	infoText: '#FFFFFF',
	warn: '#313132',
	warnBorder: '#FCBA19',
	warnIcon: '#FCBA19',
	warnText: '#FFFFFF',
	error: '#313132',
	errorBorder: '#D8292F',
	errorIcon: '#D8292F',
	errorDisabled: `rgba(216,41,47,${lightOpacity})`,
	errorText: '#FFFFFF',
};

const grayscale = {
	black: '#000000',
	blackBlueTone: '#101820',
	black40: 'rgba(16,24,32,0.2)',
	darkerGray: '#2d2926',
	darkGrey: '#313132',
	semiDarkGrey: '#2d343b',
	mediumGrey: '#606060',
	semiLightGrey: '#9d9d9d',
	lightGrey: '#D3D3D3',
	grey: '#eeeeee',
	veryLightGrey: '#F2F2F2',
	white: '#FFFFFF',
	white40: 'rgba(255,255,255,0.40)',
};

export const palette = {
	brand,
	notification,
	grayscale,
};

// type and value of light colors and dark colors will add after having the design files
// but shape of the main object should be something like this
export const lightColors = {
	background: {
		primary: grayscale.grey,
		secondary: grayscale.white,
		accent: palette.brand.primaryDark,
		highlight: palette.brand.highlight,
		highlightMelow: palette.brand.highlightDisabled,
		accentMelow: palette.brand.primaryDisabled,
		disabled: brand.primaryDisabled,
		negative: palette.notification.errorDisabled,
		formElement: brand.primaryDisabled,
	},

	gradient: {},

	text: {
		negative: notification.errorIcon,
		primary: palette.grayscale.black,
		accent: palette.brand.primaryDark,
		disabled: grayscale.mediumGrey,
	},
	border: {
		disabled: grayscale.black40,
	},
	semiTransparent: '',
};

export const darkColors = {
	background: {
		primary: grayscale.semiDarkGrey,
		secondary: grayscale.blackBlueTone,
		accent: palette.brand.primary,
		highlight: palette.brand.highlight,
		highlightMelow: palette.brand.highlightDisabled,
		accentMelow: palette.brand.primaryDisabled,
		negative: palette.notification.errorDisabled,
		disabled: brand.primaryDisabled,
		formElement: grayscale.lightTransparent1,
	},

	gradient: {},

	text: {
		negative: notification.errorIcon,
		primary: palette.grayscale.white,
		accent: palette.brand.primary,
		disabled: grayscale.semiLightGrey,
	},
	border: {
		disabled: grayscale.white40,
	},
	semiTransparent: '',
};

const fontSize = {
	xxSmall: 10,
	xSmall: 12,
	small: 14,
	semiSmall: 16,
	medium: 18,
	semiLarge: 20,
	large: 22,
	xLarge: 24,
	xxLarge: 26,
};

const fontWeight = {
	thin: 100,
	extraLight: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
	extraBold: 800,
	black: 900,
	normal: 'normal',
	boldString: 'bold',
};

const roundness = {
	full: 100,
	xLarge: 40,
	large: 20,
	medium: 10,
	small: 6,
};
const dimensions = {
	screenWidth: Dimensions.get('window').width,
	screenHeight: Dimensions.get('window').height,
	cameraHeight: Dimensions.get('window').height / 1.7,
	cameraWidth: Dimensions.get('window').width / 1.1,
};
const pads = {
	screenHorizontalPad: 33,
	screenVerticalPad: 15,
	pressablePad: 10,
	horizontalPad: 22,
	sectionVerticalPad: 33,
};

const paletteScheme = {
	dark: darkColors,
	light: lightColors,
};

export const themeConfig = {
	palette: {
		...paletteScheme,
		...palette,
	},
	paletteScheme: colorScheme
		? paletteScheme[colorScheme]
		: paletteScheme.dark,
	colorScheme,
	fonts,
	fontSize,
	fontWeight,
	roundness,
	pads,
	dimensions,
};
