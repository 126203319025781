import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { CoinIcon } from './CoinIcon';

export function BuyCoinItem({
	label,
	onPress,
	labelColor = themeConfig.paletteScheme.text.primary,
	sublabel,
	amount,
	loading,
	...props
}) {
	return (
		<View
			disabled={loading}
			row
			alignItems="center"
			borderRadius={themeConfig.roundness.medium}
			paddingHorizontal={15}
			paddingVertical={10}
			backgroundColor={themeConfig.paletteScheme.background.secondary}
			onPress={onPress}
			marginVertical={5}
			{...props}>
			<View
				paddingHorizontal={10}
				paddingVertical={5}
				style={{ minWidth: 70 }}
				row
				center
				borderRadius={5}
				marginRight={10}
				backgroundColor={
					themeConfig.paletteScheme.background.accentMelow
				}>
				<Text
					color={themeConfig.paletteScheme.text.accent}
					size={themeConfig.fontSize.xSmall}
					paddingRight={5}>
					{amount}
				</Text>
				<CoinIcon marginTop={-2} />
			</View>
			<View>
				<Text
					color={labelColor}
					paddingTop={4}
					size={themeConfig.fontSize.semiSmall}>
					{label}
				</Text>
				{!!sublabel && (
					<View
						alignSelf="flex-start"
						paddingHorizontal={5}
						paddingTop={4}
						paddingBottom={1}
						style={{
							borderRadius: themeConfig.roundness.small,
							backgroundColor:
								themeConfig.paletteScheme.background
									.accentMelow,
						}}>
						<Text size={themeConfig.fontSize.xxSmall}>
							{sublabel}
						</Text>
					</View>
				)}
			</View>
			{!!loading && (
				<View marginLeft={10}>
					<ActivityIndicator
						color={themeConfig.paletteScheme.background.accent}
					/>
				</View>
			)}
		</View>
	);
}
