import React from 'react';
import { DotIndicator } from 'react-native-indicators';
import { themeConfig } from '../../theme/themeConfig';
import { View } from '../../theme';
import { Button } from '../../theme/Button';

export function ImageLoading({ ...props }) {
	return (
		<View
			{...props}
			width="100%"
			flex
			center
			height={themeConfig.dimensions.screenHeight / 1.3}>
			<View
				alignSelf="center"
				borderRadius={themeConfig.roundness.xLarge}
				borderWidth={1}
				center
				borderColor={themeConfig.paletteScheme.text.accent}
				height={themeConfig.dimensions.screenWidth / 1.3}
				width={themeConfig.dimensions.screenWidth / 1.3}
				justifyContent="flex-start"
				alignItems="flex-start"
				paddingVertical={10}>
				<DotIndicator
					count={3}
					size={10}
					color={themeConfig.paletteScheme.text.accent}
				/>
			</View>
			<Button style={{ opacity: 0 }} marginTop={10} label="New Image" />
		</View>
	);
}
