import { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

export const useKeyboard = () => {
	const [isActive, setIsActive] = useState(false);
	const [height, setHeight] = useState(0);

	function onKeyboardDidShow(e) {
		setHeight(e.endCoordinates.height);
		setIsActive(true);
	}

	function onKeyboardDidHide() {
		setIsActive(false);
	}

	const dismiss = () => {
		Keyboard.dismiss();
	};
	useEffect(() => {
		const showSubscription = Keyboard.addListener(
			'keyboardDidShow',
			onKeyboardDidShow,
		);
		const hideSubscription = Keyboard.addListener(
			'keyboardDidHide',
			onKeyboardDidHide,
		);
		return () => {
			showSubscription.remove();
			hideSubscription.remove();
		};
	}, []);

	return { isActive, height, dismiss };
};
