import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { themeConfig } from '../../theme/themeConfig';

function EmptyError(props) {
	return (
		<Svg
			width={themeConfig.dimensions.screenWidth / 2.5}
			height={themeConfig.dimensions.screenWidth / 2.5}
			viewBox="0 0 280 280"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}>
			<Path
				d="M101.792 242.485c14.857 8.955 25.456 5.237 29.513-1.943 7.847-13.887 33.937-42.284 75.283-51.882 54.76-12.712 43.324-46.464 9.661-64.176-33.663-17.711-58.834-42.219-55.024-68.866 3.809-26.648-33.936-35.834-50.722 15.427-10.848 33.126-30.287 42.001-42.683 44.134-11.683 2.011-24.463 4.534-30.695 14.618-7.563 12.236-4.924 24.613 26.738 33.103 44.867 12.03 4.629 59.513 37.929 79.585z"
				fill={themeConfig.paletteScheme.background.secondary}
			/>
			<Path
				d="M63.191 184.424c.63-.36 7.726-3.364 43.029 13.094-.103.14-.147.206-.147.206s-36.7 12.36-43.892-2.866c-2.147-4.533-1.532-7.655.95-10.399l.06-.035zM181.847 190.652c-7.67 6.448-19.698 11.128-31.304 4.905 1.834-1.172 3.728-2.379 5.66-3.628 11.656-7.489 21.922-2.478 25.644-1.277z"
				fill={themeConfig.paletteScheme.background.accentMelow}
				stroke={themeConfig.paletteScheme.background.accent}
				strokeWidth={1}
			/>
			<Path
				d="M195.586 111.349c7.836 65.252-10.787 77.094-12.86 78.893-.6.521-.843.483-1.751.249l-.015.009c-3.841-1.018-13.101-6.06-24.757 1.428-1.932 1.249-3.826 2.456-5.66 3.628-11.89 7.541-21.492 12.855-28.409 9.771-5.868-2.994-11.158-5.584-15.915-7.809-35.302-16.458-42.399-13.454-43.028-13.094.73-.397 7.422-4.107 16.434-10.631.03-.017.06-.035.067-.059 12.045-8.729 28.176-22.485 39.77-40.18 7.893-12.033 15.615-42.537 30.514-48.522 30.619-11.423 44.338 13.044 45.61 26.317z"
				fill={themeConfig.paletteScheme.background.accent}
			/>
			<Path
				d="M161.729 110.204c.951-2.51.858-4.871-.207-5.274-1.065-.404-2.698 1.304-3.649 3.814-.95 2.51-.858 4.871.207 5.274 1.065.403 2.699-1.304 3.649-3.814zM181.225 113.406c.674-2.598.327-4.935-.775-5.221-1.103-.286-2.542 1.589-3.216 4.186-.673 2.598-.326 4.936.776 5.222 1.102.285 2.542-1.589 3.215-4.187zM169.357 122.944a.696.696 0 01-.683-.551c-.125-.565-.68-2.237-1.56-2.485-.881-.249-2.607.716-3.306 1.222a.7.7 0 11-.821-1.134c.275-.199 2.741-1.934 4.507-1.436 1.842.52 2.481 3.228 2.548 3.535a.705.705 0 01-.382.781.692.692 0 01-.303.068z"
				fill={themeConfig.paletteScheme.background.secondary}
			/>
		</Svg>
	);
}

export default EmptyError;
