import React, { useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';
import { Screen } from '../../theme/Screen';
import { Button } from '../../theme/Button';
import { themeConfig } from '../../theme/themeConfig';
import { Text, View } from '../../theme';
import { analytics, auth, crashlytics } from '../../firebase';
import { useAnalytics } from '../../firebase/useAnalytics';
import { useProfile } from '../profile/useProfile';
import EmptyError from '../emptyState/EmptyError';
import { ImageEditor } from './ImageEditor';
import { useBilling } from '../billing/useBilling';
import { ImageForm } from './ImageForm';
import { ImageLoading } from './ImageLoading';

export default function ImageScreen() {
	useAnalytics('ImageScreen');
	const profile = useProfile();
	const [prompt, setPrompt] = useState('');
	const [sending, setSending] = useState(false);
	const [data, setData] = useState();
	const { spendForImage, amount } = useBilling();
	const [token, setToken] = useState('');
	const user = auth.currentUser;
	useEffect(() => {
		user?.getIdToken().then(tk => {
			setToken(tk);
		});
	}, [user]);
	const handleSend = async () => {
		if (amount < 2) {
			Toast.show({
				type: 'error',
				text1: 'Oh No!',
				text2: 'You do not have enough coins, Please add some coins',
			});
			await analytics.logEvent('CoinsFinished');
		}
		if (!prompt || amount < 2) return;
		try {
			setPrompt('');
			setSending(true);

			const resp = await fetch(
				`https://us-central1-telepati-f930a.cloudfunctions.net/imageGeneration`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
					},
					body: JSON.stringify({
						prompt,
						// response_format: 'b64_json',
						user: profile?.uid,
					}),
				},
			);
			const json = await resp.json();
			setData(json);
			await analytics.logEvent('replyImage');
			setSending(false);
			!json?.error && spendForImage();
			!!json?.error && (await analytics.logEvent('ImageCensored'));
		} catch (error) {
			crashlytics.recordError(error, 'Image fetch error');
			console.info(error);
		}
	};
	const handleReset = () => {
		setData(null);
		setSending(false);
		setPrompt('');
	};

	if (!sending && !!data && data?.data?.length > 0)
		return <ImageEditor data={data} handleReset={handleReset} />;

	return (
		<Screen>
			{!sending && !data && (
				<ImageForm
					handleSend={handleSend}
					setPrompt={setPrompt}
					prompt={prompt}
				/>
			)}
			{sending && <ImageLoading />}
			{!sending && !!data && data?.data?.length > 0 && (
				<ImageEditor data={data} handleReset={handleReset} />
			)}
			{!sending && !!data?.error && (
				<View
					width="100%"
					flex
					center
					height={themeConfig.dimensions.screenHeight / 1.3}>
					<View
						alignSelf="center"
						borderRadius={themeConfig.roundness.xLarge}
						borderWidth={1}
						center
						borderColor={
							themeConfig.palette.notification.errorBorder
						}
						height={themeConfig.dimensions.screenWidth / 1.3}
						width={themeConfig.dimensions.screenWidth / 1.3}
						justifyContent="flex-start"
						alignItems="flex-start"
						paddingVertical={10}>
						<EmptyError />
						<Text paddingHorizontal={10}>
							{data?.error?.message}
						</Text>
					</View>
					<Button
						onPress={handleReset}
						marginTop={10}
						label="New Image"
					/>
				</View>
			)}
		</Screen>
	);
}
