import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';
import { View } from './View';

export function FadeView({
	onPress,
	children,
	duration = 1000,
	visible = true,
	delay = 10,
	...props
}) {
	const fadeAnim = useRef(new Animated.Value(0)).current;

	useEffect(() => {
		Animated.timing(fadeAnim, {
			toValue: visible === false ? 0 : 1,
			duration,
			useNativeDriver: true,
			delay: delay || 0,
		}).start();
	}, [delay, duration, fadeAnim, visible]);

	return (
		<Animated.View
			style={{
				opacity: fadeAnim, // Bind opacity to animated value
			}}>
			<View onPress={onPress} {...props}>
				{children}
			</View>
		</Animated.View>
	);
}
export default FadeView;
