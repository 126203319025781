import React from 'react';
import { FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import { Screen } from '../../theme/Screen';
import { Text, View } from '../../theme';
import { isChromeExtension, isWeb, themeConfig } from '../../theme/themeConfig';
import { EmptyState } from '../emptyState/EmptyState';
import { ChatHistoryItem } from './ChatHistoryItem';
import { useAnalytics } from '../../firebase/useAnalytics';
import { useProfile } from '../profile/useProfile';
import { useChatLogic } from './useChatLogic';

export function ChatListScreen() {
	useAnalytics('HistoryScreen');
	const profile = useProfile();
	const { chats = [] } = useChatLogic();

	const navigation = useNavigation();
	const renderChatHistory = ({ item, index }) => (
		<ChatHistoryItem profile={profile} index={index} item={item} />
	);

	// eslint-disable-next-line no-unused-vars
	function sortByLastMessageDate(a, b) {
		if (!b?.insertDate || a?.insertDate) return 0;
		return moment(b?.insertDate).unix() - moment(a?.insertDate).unix();
	}

	const handleClickNew = () => {
		if (isWeb || isChromeExtension || profile?.uid) {
			navigation.navigate('Home', { newChat: true });
		} else {
			navigation.navigate('AdScreen', { newChat: true });
		}
	};

	return (
		<Screen>
			<View paddingVertical={10} flex>
				<FlatList
					showsVerticalScrollIndicator={false}
					ItemSeparatorComponent={() => (
						<View width={10} height={10} />
					)}
					ListEmptyComponent={
						<View
							center
							height={themeConfig.dimensions.screenHeight / 1.3}>
							<EmptyState
								description="No previous message found!"
								actionLabel="Start chatting"
								onPress={() => navigation.navigate('Home')}
								materialCommunityIcon="history"
							/>
						</View>
					}
					data={chats}
					renderItem={renderChatHistory}
				/>
			</View>
			<View center>
				<View
					onPress={handleClickNew}
					borderRadius={themeConfig.roundness.small}
					paddingLeft={10}
					paddingRight={13}
					paddingTop={8}
					paddingBottom={5}
					backgroundColor={
						themeConfig.paletteScheme.background.secondary
					}>
					<Text color={themeConfig.paletteScheme.text.accent}>
						+ New Chat
					</Text>
				</View>
			</View>
		</Screen>
	);
}
