import React from 'react';
import { Image } from 'react-native';
import { useDispatch } from 'react-redux';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { useActivePersonas } from './usePersonas';
import { reduxPersonaSelect } from '../redux/actions';

export function PersonaItem({ item }) {
	const activePersona = useActivePersonas();
	const isActive =
		item?.title === activePersona?.title &&
		item?.assets === activePersona?.assets;
	const dispatch = useDispatch();
	return (
		<View
			onPress={() => dispatch(reduxPersonaSelect(item))}
			center
			backgroundColor={
				isActive
					? themeConfig.paletteScheme.background.accent
					: 'transparent'
			}
			paddingHorizontal={10}
			borderRadius={themeConfig.roundness.large}
			paddingVertical={10}>
			<Image
				source={item.assets}
				style={{
					width: 60,
					height: 60,
					borderRadius: themeConfig.roundness.large,
					borderColor: themeConfig.paletteScheme.text.accent,
					borderWidth: 1,
				}}
			/>
			<Text
				size={themeConfig.fontSize.semiSmall}
				color={
					isActive
						? themeConfig.paletteScheme.background.primary
						: themeConfig.paletteScheme.text.primary
				}
				paddingTop={10}>
				{item?.title}
			</Text>
		</View>
	);
}
