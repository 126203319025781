import React, { useEffect, useState } from 'react';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { Alert, Linking } from 'react-native';
import * as Crypto from 'expo-crypto';
import { DotIndicator } from 'react-native-indicators';
import { useNavigation } from '@react-navigation/native';
import { useProfile } from '../profile/useProfile';
import { auth, authInstance, firestore } from '../../firebase';
import { BackButton } from '../../theme/BackButton';
import { Text, View } from '../../theme';
import { themeConfig } from '../../theme/themeConfig';
import { EmptyState } from '../emptyState/EmptyState';
import { Screen } from '../../theme/Screen';
import QrCodeFrame from './QrCodeFrame';

export function RegisterChromeExtensionCamera() {
	const [key, setKey] = useState();
	const [hasPermission, setHasPermission] = useState(null);
	const [scanned, setScanned] = useState(false);
	const profile = useProfile();
	const email = `${profile?.email}`;
	const navigation = useNavigation();
	useEffect(() => {
		const getBarCodeScannerPermissions = async () => {
			const { status } = await BarCodeScanner.requestPermissionsAsync();
			setHasPermission(status === 'granted');
		};

		getBarCodeScannerPermissions();
	}, []);

	const handleBarCodeScanned = ({ data }) => {
		setScanned(true);
		!!data && typeof data === 'string' && setKey(data);
	};

	useEffect(() => {
		if (!key) return;
		const register = async token => {
			const registerRecord = await firestore
				.collection('chromeExtensions')
				.doc(key);
			const docData = await registerRecord.get();
			if (!docData.exists) {
				Alert.alert(
					'Error',
					'The scanned code is not valid. try again.',
					[
						{
							text: 'OK',
						},
					],
					{ cancelable: false },
				);
				return;
			}

			await registerRecord
				.update({ party: profile?.email || email, token })
				.then(() => {
					console.info('uploaded');
					// setIsRegistered(true);
				})
				.catch(err => {
					console.error(err);
				});
		};
		const user = auth.currentUser;

		if (user) {
			Crypto.digestStringAsync(
				Crypto.CryptoDigestAlgorithm.MD5,
				profile?.uid,
			).then(token => {
				// check if user already linked with email
				const providerId = authInstance.EmailAuthProvider.PROVIDER_ID;
				if (user.providerData.some(p => p.providerId === providerId)) {
					// User can sign in with EmailAuthProvider credential
					register(token).then(() => {
						console.info('Registered');
					});
					return;
				}
				// User can sign in with EmailAuthProvider credential
				const provider = authInstance.EmailAuthProvider;
				const authCredential = provider?.credential(email, token);
				user.linkWithCredential(authCredential)
					.then(() => {
						register(token).then(() => {
							console.info('Registered');
						});
					})
					.catch(error => {
						console.error('Account linking error', error);
					});
			});
		}
	}, [email, key, profile?.email, profile?.uid]);

	const openSettings = async () => {
		await Linking.openSettings();
	};

	return (
		<Screen flex>
			<BackButton onPress={() => navigation.replace('Tabs')} />
			<View flex center>
				{hasPermission === null && (
					<DotIndicator
						color={themeConfig.paletteScheme.text.accent}
						size={10}
					/>
				)}
				{hasPermission === false && (
					<EmptyState
						materialCommunityIcon="camera"
						description={"App need's to have access to camera"}
						actionLabel="Grant Access"
						onPress={openSettings}
					/>
				)}
				{hasPermission && (
					<>
						<View flex>
							<BarCodeScanner
								onBarCodeScanned={
									scanned ? undefined : handleBarCodeScanned
								}
								// barCodeTypes={[BarCodeScanner.Constants.BarCodeType.qr]}
								style={{
									resizeMode: 'contain',
									flex: 1,
									width: themeConfig.dimensions.screenWidth,
									height: themeConfig.dimensions.screenHeight,
								}}
							/>
						</View>
						<View flex position="absolute" center>
							<View center flex>
								<QrCodeFrame
									color={
										themeConfig.paletteScheme.text.accent
									}
									height={200}
									width={200}
								/>
								<Text
									weight={600}
									size={themeConfig.fontSize.small}
									textAlign="center"
									color={
										themeConfig.paletteScheme.text.accent
									}>
									Please scan the QR code on Telepati Chrome
									Extension
								</Text>
							</View>
						</View>
					</>
				)}
			</View>
		</Screen>
	);
}
